import { Box, Container, Typography } from "@mui/material";
import React from "react";
import NavBar from "../../../Shared/NavBar/NavBar";
import bgImg from "../../../asstes/img/about.avif";

const AboutUs = () => {
  return (
    <Box sx={{ height: "100%" }}>
      <NavBar />
      <Box>
        <Container sx={{ my: "100px", bgcolor: "white", borderRadius: "3px" }}>
          <Box sx={{ pt: "20px" }}>
            <Typography
              sx={{
                color: "var(--primary-button)",
                fontWeight: 600,
                fontSize: "22px",
              }}
            >
              About US
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              mt: "20px",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{ fontWeight: 600, fontSize: "22px", textAlign: "justify" }}
              >
                Making travel joyful and easy,
                <br /> we are all about your journey.
              </Typography>
              <Typography
                sx={{
                  color: "var(--flight-color)",
                  textAlign: "justify",
                  mt: "20px",
                }}
              >
                We believe that when our customers win, we win. Since 2024,
                flyitsearch has helped travelers experience the joy of traveling
                without facing any hassle.
              </Typography>
              <Box>
                <Typography
                  sx={{
                    color: "var(--flight-color)",
                    textAlign: "justify",
                    mt: "50px",
                  }}
                >
                  Thanks to our parent company, "Makkah Group," with experience
                  of more than 30 years, we have earned the trust and love of
                  the travelers who are continuously doing business with us.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "100%", height: "300px" }}>
              <img
                style={{ height: "100%", width: "100%", borderRadius: "10px" }}
                src={bgImg}
              />
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "var(--primary-button)",
                fontSize: "22px",
                fontWeight: 600,
              }}
            >
              Our Mission
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                textAlign: "justify",
                mt: "5px",
              }}
            >
              Our mission at Flyitsearch is simple yet impactful: To
              revolutionize the travel industry by offering the first-ever fully
              functional online travel agency (OTA) in Bangladesh.
            </Typography>
            <Typography
              sx={{
                color: "var(--flight-color)",
                textAlign: "justify",
                py: "50px",
              }}
            >
              We aim to unlock the full potential of online travel solutions,
              allowing people to book flights, hotels, and more from the comfort
              of their homes. By prioritizing our customers’ needs, we strive to
              eliminate complexities, ensuring a hassle-free experience that
              puts convenience and quality at the forefront.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "var(--primary-button)",
                fontSize: "22px",
                fontWeight: 600,
              }}
            >
              Our Vission
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                textAlign: "justify",
                mt: "5px",
              }}
            >
              At Flyitsearch, we envision a world where travel is no longer a
              challenge but a cherished experience for everyone.
            </Typography>
            <Typography
              sx={{
                color: "var(--flight-color)",
                textAlign: "justify",
                py: "50px",
              }}
            >
              Our goal is to set new benchmarks in service quality, making it
              easy for people to connect with the destinations and experiences
              they dream of. Through innovation and a customer-first approach,
              we aim to make Flyitsearch the trusted travel partner for millions
              worldwide.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "var(--primary-button)",
                fontSize: "22px",
                fontWeight: 600,
              }}
            >
              Our Story
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                textAlign: "justify",
                mt: "5px",
              }}
            >
              Our story is the story of 1st ever fully functional online travel
              agency of Bangladesh
            </Typography>
            <Typography
              sx={{
                color: "var(--flight-color)",
                textAlign: "justify",
                py: "50px",
              }}
            >
              The story of Flyitsearch is rooted in a passion for transforming
              how people travel. As the first fully operational online travel
              agency in Bangladesh, we have pioneered a platform that bridges
              technology and personalized service. Our journey began with a
              mission to simplify travel, and today, we continue to evolve,
              driven by the belief that every journey matters. With each click
              and every booking, we bring people closer to their dreams, making
              Flyitsearch synonymous with trust, innovation, and unparalleled
              service.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUs;
