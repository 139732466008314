import { Box, CircularProgress, Typography } from "@mui/material";
import commaNumber from "comma-number";
import React from "react";

const FareBreakdown = ({  airPriceData,loading }) => {
  console.log("airPriceData", airPriceData);

  return (
    <Box>
{
  loading ? <> <CircularProgress color="secondary" /></> :<Box sx={{ width: "100%" }}>
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <Typography sx={{ fontSize: "12px", color: "#293247" }}>
      Fare Breakdown
    </Typography>
    <Typography sx={{ fontSize: "11px", color: "#71727C" }}>
      Price are shown in BDT
    </Typography>
  </Box>

  <Box sx={{ bgcolor: "#FFFFFF", p: "15px", mt: "20px" }}>
    <Box>
      {airPriceData[0]?.PriceBreakDown?.map((price, i) => (
        <Box
          key={i}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ fontSize: "13px", color: "#71727C", fontWeight: 600 }}
          >
            Traveler {i + 1}: {price?.PaxType} (x{price?.PaxCount})
          </Typography>
          <Typography
            sx={{ fontSize: "13px", color: "#71727C", fontWeight: 600 }}
          >
            {commaNumber(price?.BaseFare * price?.PaxCount)}
          </Typography>
        </Box>
      ))}
    </Box>
    <Box mt={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "1px solid #D9D9D9",
          pt: "2px",
        }}
      >
        <Typography
          sx={{ fontSize: "13px", color: "#71727C", fontWeight: 600 }}
        >
          Total Base Fare
        </Typography>
        <Typography
          sx={{ fontSize: "13px", color: "#71727C", fontWeight: 600 }}
        >
          {commaNumber(airPriceData[0]?.BaseFare)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: "8px",
        }}
      >
        <Typography
          sx={{ fontSize: "13px", color: "#71727C", fontWeight: 600 }}
        >
          Total Tax & Fee
        </Typography>
        <Typography
          sx={{ fontSize: "13px", color: "#71727C", fontWeight: 600 }}
        >
          {commaNumber(
            airPriceData[0]?.Taxes + airPriceData[0]?.SerViceFee
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: "8px",
          pb: "15px",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <Typography
          sx={{ fontSize: "13px", color: "#71727C", fontWeight: 600 }}
        >
          Discount
        </Typography>
        <Typography
          sx={{ fontSize: "13px", color: "#71727C", fontWeight: 600 }}
        >
          {commaNumber(
            airPriceData[0]?.GrossFare - airPriceData[0]?.NetFare
          )}
        </Typography>
      </Box>
    </Box>
    <Box my={3}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ fontSize: "14px", color: "#293247", fontWeight: 600 }}
        >
          Grand Total
        </Typography>
        <Typography
          sx={{ fontSize: "14px", color: "#293247", fontWeight: 600 }}
        >
          {commaNumber(airPriceData[0]?.NetFare)}
        </Typography>
      </Box>
    </Box>
  </Box>
</Box>
}
    </Box>
    
  );
};

export default FareBreakdown;
