import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import NavBar from "../../../Shared/NavBar/NavBar";
import banner1 from "../../../asstes/img/tour/tourDetails/banner1.png";
import banner2 from "../../../asstes/img/tour/tourDetails/banner2.png";
import banner3 from "../../../asstes/img/tour/tourDetails/banner3.png";
import banner4 from "../../../asstes/img/tour/tourDetails/banner4.png";
import banner5 from "../../../asstes/img/tour/tourDetails/banner5.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import place1 from "../../../asstes/img/tour/tourDetails/place2.png";
import place2 from "../../../asstes/img/tour/tourDetails/place3.png";
import place3 from "../../../asstes/img/tour/tourDetails/place4.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TourDeatils = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <NavBar />
      <Container sx={{ pt: "40px" }}>
        <Grid container spacing={3}>
          <Grid item md={4} lg={5.5}>
            <Box>
              <img style={{ width: "100%", height: "440px" }} src={banner1} />
            </Box>
          </Grid>
          <Grid item md={8} lg={6.5} container>
            <Grid container spacing={3}>
              <Grid item={6}>
                <Box>
                  <img style={{ width: "100%" }} src={banner2} />
                </Box>
              </Grid>
              <Grid item={6}>
                <Box>
                  <img style={{ width: "100%" }} src={banner3} />
                </Box>
              </Grid>
              <Grid item={6}>
                <Box>
                  <img style={{ width: "100%" }} src={banner4} />
                </Box>
              </Grid>
              <Grid item={6}>
                <Box>
                  <img style={{ width: "100%" }} src={banner5} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ py: "20px" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                color: "var(--tour-color)",
                fontSize: "16px",
              }}
            >
              Bangladesh
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                color: "var(--meta-black)",
                fontSize: "22px",
                py: "3px",
              }}
            >
              Land Of Himalayan Majesty And Cultural Riches{" "}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                color: "var(--tour-color)",
                fontSize: "15px",
              }}
            >
              3 Nights 4 Days{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
                py: "10px",
              }}
            >
              <LocationOnIcon sx={{ color: "#9C9797" }} />
              <Typography sx={{ color: "#9C9797", fontSize: "14px" }}>
                Tanguar Haor, Sunamganj
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "30px",
                py: "15px",
              }}
            >
              <Box
                sx={{
                  width: "85px",
                  height: "25px",
                  bgcolor: "var(--primary-color)",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ color: "#716200", fontSize: "12px" }}>
                  Family Tour
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "85px",
                  height: "25px",
                  bgcolor: "var(--primary-color)",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ color: "#716200", fontSize: "12px" }}>
                  Group Tour
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "85px",
                  height: "25px",
                  bgcolor: "var(--primary-color)",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ color: "#716200", fontSize: "12px" }}>
                  Relax Tour
                </Typography>
              </Box>
            </Box>

            <Box sx={{ py: "40px" }}>
              <Typography
                sx={{ fontWeight: 600, color: "#225F82", fontSize: "20px" }}
              >
                Overview
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#34343E",
                  fontSize: "13px",
                  py: "5px",
                }}
              >
                Home to majestic mountain ranges and picture like islands;
                Europe possesses some of the most beautiful scenery on Earth. We
                are going to have 20 days long road trip and touch nine
                exceptionally exotic countries of Europe. There simply is no way
                not be awestruck by its scenic beauty and artistic diversity.
                What is great about Europe is their cultural diversity. People
                even speak different languages. Some of their most unique
                attractions are the Eiffel Tower in Paris, France; Colosseum in
                Rome, Italy; Parthenon in Athens, Greece; and the Leaning Tower
                of Pisa, Italy. They are also known for their epic cuisines;
                like most popular Italian cuisine, French desserts,
                Switzerland’s chocolate etc. We are definitely going to have a
                blast in Europe. Europe is their cultural diversity. People even
                speak different languages. Some of their most unique attractions
                are the Eiffel Tower in Paris, France; Colosseum in Rome, Italy;
                Parthenon in Athens, Greece; and the Leaning Tower of Pisa,
                Italy. They are also known for their epic cuisines; like most
                popular Italian cuisine, French desserts, Switzerland’s
                chocolate etc. We are definitely going to have a blast in
                Europe.
              </Typography>
            </Box>
            <Box sx={{ py: "40px" }}>
              <Typography
                sx={{ fontWeight: 600, color: "#225F82", fontSize: "20px" }}
              >
                Place you will see
              </Typography>
              <Box sx={{ py: "20px", display: "flex", gap: "25px" }}>
                <Box sx={{ position: "relative" }}>
                  <Box>
                    <img src={place3} />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10%",
                      left: "0%",
                      p: "0px 20px",
                    }}
                  >
                    <Typography
                      sx={{ color: "var(--white)", fontSize: "20px" }}
                    >
                      Paris
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ position: "relative" }}>
                  <Box>
                    <img src={place1} />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10%",
                      left: "0%",
                      p: "0px 20px",
                    }}
                  >
                    <Typography
                      sx={{ color: "var(--white)", fontSize: "20px" }}
                    >
                      Italy
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ position: "relative" }}>
                  <Box>
                    <img src={place2} />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10%",
                      left: "0%",
                      p: "0px 20px",
                    }}
                  >
                    <Typography
                      sx={{ color: "var(--white)", fontSize: "20px" }}
                    >
                      Poland
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ py: "40px" }}>
              <Typography
                sx={{ fontWeight: 600, color: "#225F82", fontSize: "20px" }}
              >
                Tour Plan
              </Typography>
              <Box sx={{ py: "30px" }}>
                <Box>
                  <Accordion sx={{ background: "var(--white)" }}>
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownIcon
                          sx={{ color: "#225F82", fontSize: "35px" }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#225F82",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Day - 1
                        </Typography>
                        <Typography
                          sx={{
                            color: "#225F82",
                            fontWeight: 500,
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          Italy - After landing{" "}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ borderTop: "1px solid #225F82" }}>
                      <ul>
                        <li
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#225F82",
                          }}
                        >
                          Italy - After landing at Milan Airport, you will be
                          greeted and then transferred to the desired
                          accommodation for check in.
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion sx={{ background: "var(--white)", mt: "10px" }}>
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownIcon
                          sx={{ color: "#225F82", fontSize: "35px" }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#225F82",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Day - 2
                        </Typography>
                        <Typography
                          sx={{
                            color: "#225F82",
                            fontWeight: 500,
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          Italy - After landing{" "}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ borderTop: "1px solid #225F82" }}>
                      <ul>
                        <li
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#225F82",
                          }}
                        >
                          Italy - After landing at Milan Airport, you will be
                          greeted and then transferred to the desired
                          accommodation for check in.
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion sx={{ background: "var(--white)", mt: "10px" }}>
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownIcon
                          sx={{ color: "#225F82", fontSize: "35px" }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#225F82",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Day - 3
                        </Typography>
                        <Typography
                          sx={{
                            color: "#225F82",
                            fontWeight: 500,
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          Italy - After landing{" "}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ borderTop: "1px solid #225F82" }}>
                      <ul>
                        <li
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#225F82",
                          }}
                        >
                          Italy - After landing at Milan Airport, you will be
                          greeted and then transferred to the desired
                          accommodation for check in.
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Box>
            <Box sx={{ py: "40px" }}>
              <Typography
                sx={{ fontWeight: 600, color: "#225F82", fontSize: "20px" }}
              >
                Objectives
              </Typography>
              <Box sx={{ py: "30px" }}>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      borderBottom: "3px solid #3687B7 !important",
                      borderColor: "divider",
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="Inclussion"
                        {...a11yProps(0)}
                        sx={{ width: "25%", textTransform: "capitalize" }}
                      />
                      <Tab
                        label="Exclussion"
                        {...a11yProps(1)}
                        sx={{ width: "25%", textTransform: "capitalize" }}
                      />
                      <Tab
                        label="Booking Policy"
                        {...a11yProps(2)}
                        sx={{ width: "25%", textTransform: "capitalize" }}
                      />
                      <Tab
                        label="Refund Policy"
                        {...a11yProps(3)}
                        sx={{ width: "25%", textTransform: "capitalize" }}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <Box>
                      <ul>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            fontWeight: 600,
                          }}
                        >
                          Standard accommodation on double/ triple sharing basis
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          2 meals per day (Breakfast and Dinner)
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          Sight-seeing
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          English Speaking Guide.
                        </li>
                      </ul>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <Box>
                      <ul>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            fontWeight: 600,
                          }}
                        >
                          Any personal expenses
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          Any food during transit.
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          Corona test cost (compulsory).
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          Bills for receiving any facility from the hotel.
                        </li>
                      </ul>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <Box>
                      <ul>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            fontWeight: 600,
                          }}
                        >
                          Each event will have specific booking times, and seats
                          must be booked by paying at least 50% of the booking
                          amount within that period.
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          After making the payment, you must send the last 3
                          digits of the Bank Receipt/Bkash or a photo of the
                          cash receipt (in the case of cash payment) to our
                          Official page/WhatsApp/E-mail.
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          Along with the payment, provide clear photos of your
                          national identity card, passport-size photo, phone
                          number, and emergency contact number. After providing
                          all these details, you will receive an official
                          confirmation. Failure to provide the necessary
                          information will result in pending confirmation. You
                          will receive our terms and conditions along with the
                          confirmation, and you must agree to them.
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          The booking amount is non-refundable if the booking is
                          canceled due to negligence or delay in providing the
                          necessary documents, such as the National Identity
                          Card and others.
                        </li>
                      </ul>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <Box>
                      <ul>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            fontWeight: 600,
                          }}
                        >
                          Standard accommodation on double/ triple sharing basis
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          2 meals per day (Breakfast and Dinner)
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          Sight-seeing
                        </li>
                        <li
                          style={{
                            color: "var(--meta-black)",
                            fontSize: "13px",
                            paddingTop: "15px",
                            fontWeight: 600,
                          }}
                        >
                          English Speaking Guide.
                        </li>
                      </ul>
                    </Box>
                  </CustomTabPanel>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TourDeatils;
