import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Router,
  Routes,
  useLocation,
} from "react-router-dom";
import NavBar from "./Shared/NavBar/NavBar";
import Home from "./Pages/Home/Home";
import Footer from "./Shared/Footer/Footer";
import SignIn from "./Pages/SignUp/SignIn/SignIn";
import SignUp from "./Pages/SignUp/SignUp";
import AfterSearchBanner from "./Pages/AfterSearch/AfterSearchBanner";
import OneWaySearchResult from "./Pages/OneWay/OneWaySearchResult";
import TourHome from "./Pages/Tour/TourHome/TourHome";
import TourSearchResult from "./Pages/Tour/TourSearchResult/TourSearchResult";
import TourDeatils from "./Pages/Tour/TourDeatils/TourDeatils";
import RoundWaySearchResult from "./Pages/RoundWay/RoundWaySearchResult";
import BookingDetaills from "./Pages/BookingDetaills/BookingDetaills";
import BookingQueInfo from "./Pages/BookingQueInfo/BookingQueInfo";
import UserInfo from "./Pages/UserInfo/UserInfo";
import UserProfileUpdated from "./Pages/UserInfo/UserProfile/UserProfileUpdated";
import PaymentGateway from "./Pages/PaymentGateway/PaymentGateway";
import UserInfoV2 from "./Pages/UserInfoV2/UserInfoV2";
import UserProfile from "./Pages/UserInfo/UserProfile/UserProfile";
import UserBooking from "./Pages/UserInfo/UserBooking/UserBooking";
import UserWallet from "./Pages/UserInfo/UserWallet/UserWallet";
import AddDeposite from "./Pages/UserInfo/UserWallet/AddDeposite";
import UserTransactions from "./Pages/UserInfo/UserTransactions/UserTransactions";
import UserTraveler from "./Pages/UserInfo/UserTraveler/UserTraveler";
import AddTraveler from "./Pages/UserInfo/UserTraveler/AddTraveler";
import MultiCitySearchResult from "./Pages/MultiCity/MultiCitySearchResult";
import PaymentSuccess from "./Pages/PaymentGateway/PaymentSuccess/PaymentSuccess";
import PaymentCancelled from "./Pages/PaymentGateway/PaymentCancelled/PaymentCancelled";
import ScrollToTop from "./ScrollToTop";
import PaymentFailed from "./Pages/PaymentGateway/PaymentFailed/PaymentFailed";
import OtpVerification from "./Pages/SignUp/OtpVerification";
import ForgetEmail from "./Pages/SignUp/ForgetEmail";
import ChangePassword from "./Pages/SignUp/ChangePassword";
import GoogleLogIn from "./Pages/GoogleLogIn";
import PrivacyPolicy from "./Pages/Policy/PrivacyPolicy/PrivacyPolicy";
import NoBookingsFound from "./Shared/AnimationError/NoBookingsFound/NoBookingsFound";
import PageNotFound from "./Shared/AnimationError/NoBookingsFound/PageNotFound";
import ChatBox from "./Pages/ChatBox/ChatBox";
import AboutUs from "./Pages/Policy/AboutUs/AboutUs";
import FlightLoading from "./Pages/OneWay/FlightLoading";
import Booking from "./Pages/UserInfo/UserBooking/Booking";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/onewaysearchresult" element={<OneWaySearchResult />} />
          <Route path="/OtpVerification" element={<OtpVerification />} />
          <Route path="/OtpEmail" element={<ForgetEmail />} />
          <Route path="/resetpassword" element={<ChangePassword />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/nobooking" element={<NoBookingsFound />} />
          <Route
            path="/roundwaysearchresult"
            element={<RoundWaySearchResult />}
          />
          <Route
            path="/multicitysearchresult"
            element={<MultiCitySearchResult />}
          />
          {/* booking route  */}
          <Route path="/bookingdetails" element={<BookingDetaills />} />
          <Route path="/bookingquedetails" element={<BookingQueInfo />} />
          <Route path="/paymentgatewaty" element={<PaymentGateway />} />
          <Route path="/paymentsuccess" element={<PaymentSuccess />} />
          <Route path="/paymentcancelled" element={<PaymentCancelled />} />
          <Route path="/paymentfailed" element={<PaymentFailed />} />
          {/* userDashbord Route  */}
          <Route path="/userinfov2" element={<UserInfoV2 />}>
            <Route path="userprofile" element={<UserProfile />} />
            {/* <Route path="userbooking" element={<UserBooking />} /> */}
            <Route path="userbooking" element={<Booking />} />
            <Route path="userwallet" element={<UserWallet />} />
            <Route path="adddeposite" element={<AddDeposite />} />
            <Route path="usertransctions" element={<UserTransactions />} />
            <Route path="usertraveler" element={<UserTraveler />} />
            <Route path="addtravler" element={<AddTraveler />} />
            <Route path="updateprofilev2" element={<UserProfileUpdated />} />
          </Route>
          {/* tour routs  */}
          <Route path="/toursearchresult" element={<TourSearchResult />} />
          <Route path="/tourdetails" element={<TourDeatils />} />
          <Route path="/tour" element={<TourHome />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/test" element={<GoogleLogIn />} />
          <Route path="/test1" element={<FlightLoading />} />
          <Route path="*" element={<PageNotFound />} /> {/* Not Found route */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
