import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const FarePolicy = ({ searchId, resultId }) => {
  const [fareRuls, setFareRuls] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const api =
          "https://flyitsearch-backend-api-539319089408.asia-east1.run.app/flights/flh/fairRules";
        const body = { SearchId: searchId, ResultId: resultId };

        const response = await fetch(api, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        if (response.ok) {
          const data = await response.json();
          setFareRuls(data);
        } else {
          console.error("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchId, resultId]);

  return (
    <Box sx={{ width: "100%" }}>
      {fareRuls && fareRuls.length > 0 ? (
        <Box>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="fare rules tabs"
          >
            {fareRuls.map((ruls, i) => (
              <Tab
                style={{ fontSize: "13px", textTransform: "capitalize" }}
                key={i}
                label={ruls?.RuleType || `Rule ${i + 1}`}
              />
            ))}
          </Tabs>

          {/* TabPanel for RuleDetails */}
          {fareRuls.map((ruls, i) => (
            <TabPanel key={i} value={tabIndex} index={i}>
              <div
                style={{ fontSize: "12px", textTransform: "capitalize" }}
                dangerouslySetInnerHTML={{ __html: ruls?.RuleDetails }}
              />
            </TabPanel>
          ))}
        </Box>
      ) : (
        <Typography sx={{ fontSize: "14px", color: "var(--meta-black)" }}>
          Fare rules not available
        </Typography>
      )}
    </Box>
  );
};

export default FarePolicy;
