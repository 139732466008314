import { Box } from "@mui/material";
import React from "react";
import NavBar from "../../../Shared/NavBar/NavBar";
import TourBanner from "./TourBanner";
import TourBestDestination from "../TourBestDestination/TourBestDestination";
import TourOffer from "../TourOffer/TourOffer";
import AllTourPackage from "../AllTourPackage/AllTourPackage";
import TourExpert from "../TourExpert/TourExpert";

const TourHome = () => {
  return (
    <Box>
      <NavBar />
      <TourBanner/>
      <TourBestDestination/>
      <TourOffer/>
      <AllTourPackage/>
      <TourExpert/>
    </Box>
  );
};

export default TourHome;
