import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as DepartureIcons } from "../../../asstes/img/HomeImage/flightIcons/deapature.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link } from "react-router-dom";

const TourSearchBox = () => {
  return (
    <Box>
      <Grid container>
        <Grid item md={5}>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography sx={{ color: "var(--text-color)", fontSize: "14px" }}>
                Destinations
              </Typography>
              <DepartureIcons />
            </Box>
            <Box sx={{ pt: "5px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "var(--flight-color)",
                }}
              >
                Dhaka, Bangladesh
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={5} sx={{ borderLeft: "1px solid var(--text-color)" }}>
          <Box sx={{ pl: "20px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography sx={{ color: "var(--text-color)", fontSize: "14px" }}>
                Select Date
              </Typography>
              <CalendarMonthIcon
                sx={{ fontSize: "18px", color: "var(--text-color)" }}
              />
            </Box>
            <Box sx={{ pt: "5px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "var(--flight-color)",
                }}
              >
                July 29, 2023
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid md={2}>
          <Link to="/toursearchresult">
            <Button
              sx={{
                color: "var(--primary-text-color)",
                textTransform: "capitalize",
                borderRadius: "10px",
                fontWeight: 600,
                width: "100%",
                bgcolor: "var(--button-color)",
                ":hover": {
                  bgcolor: "var(--button-color)",
                },
              }}
            >
              Search
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TourSearchBox;
