import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const PNRHistory = () => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion
      sx={{ background: "white", boxShadow: "none" }}
      expanded={!expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={
          <ArrowDropDownIcon
            sx={{ color: "var(--primary-button)", fontSize: "35px" }}
          />
        }
      >
        <Typography
          sx={{
            color: "var(--primary-button)",
            fontWeight: 600,
            fontSize: "15px",
          }}
        >
          PNR History
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ background: "white" }}>
        <Typography>content is Coming soon!!!!!!!!!!!!!!!!!!!</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default PNRHistory;
