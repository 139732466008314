import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Stack, Tooltip } from "@mui/material";
import "./AirlinesSlider.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import commaNumber from "comma-number";

const AirlinesSlider = ({ uniqueCarriers, selectedAirlins, handleAirLine }) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  const sliderRef = React.createRef();

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };
  const getBoxColor = (row, col) => {
    return (row + col) % 2 === 0 ? "red" : "blue";
  };
  // const airlineNames = Object?.values(
  //   flights?.reduce((acc, flight) => {
  //     const { Carrier, CarrierName, NetFare, System } = flight;
  //     const clientPrice = NetFare; // Assuming NetFare is a number

  //     if (!acc[Carrier] || acc[Carrier].clientPrice > clientPrice) {
  //       acc[Carrier] = {
  //         careerName: CarrierName,
  //         career: Carrier,
  //         clientPrice: clientPrice,
  //       };
  //     }

  //     return acc;
  //   }, [])
  // );

  // var settings = {
  //   dots: false,
  //   arrows: true,
  //   infinite: false,
  //   speed: 500,
  //   autoplay: false,
  //   autoplaySpeed: 1000,
  //   slidesToShow: airlineNames.length > 6 ? 5 : 2,
  //   slidesToScroll: airlineNames.length > 6 ? 5 : 2,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //       },
  //     },
  //   ],
  // };

  return (
    <Box
      className="airlines-slide"
      sx={{
        width: { xs: "90vw", sm: "90vw", md: "100%", lg: "100%" },
        button: {
          border: "none",
          outline: "none",
          backgroundColor: "var(--primary-color)",
          color: "var(--white)",
          cursor: "pointer",
          borderRadius: "50%",
          height: "25px",
          width: "25px",
          margin: "auto",
          marginLeft: "5px",
          marginRight: { xs: "1px", md: "1px" },
          overflow: "hidden",
          paddingTop: "4px",
          "&:hover": {
            color: "var(--black)",
            backgroundColor: "var(--bgcolor)",
          },
        },
        ".slick-next": {
          display: "none !important",
        },
        ".slick-prev": {
          display: "none !important",
        },

        ".slick-track": {
          marginLeft: 0,
        },
        ".slick-slide > div": {
          margin: "1px 5px",
          width: "auto",
        },
        ".slick-list": {
          margin: "0px -10px",
        },
        px: 0,
      }}
      position="relative"
    >
      <Box
        sx={{
          borderRadius: "10px",
        }}
      >
        <Slider ref={sliderRef} {...settings} nextArrow={null} prevArrow={null}>
          {uniqueCarriers.map((item, i) => {
            const isEven = i % 2 === 0;
            const boxColor = isEven ? "even-color" : "odd-color";
            return (
              <Box
                key={i}
                sx={{ borderRadius: "6px" }}
                className={`${boxColor}`}
              >
                <input
                  type="checkbox"
                  id={item.code}
                  checked={selectedAirlins.includes(item.code)}
                  onChange={() => handleAirLine(item.code)}
                  style={{
                    position: "absolute",
                    zIndex: "-1",
                    visibility: "hidden",
                  }}
                />
                <label htmlFor={item.code}>
                  <div>
                    <Tooltip title={item?.name}>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          zIndex: 2,
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "6px",
                          p: 1,
                          backgroundColor: selectedAirlins.includes(item.code)
                            ? "var(--primary-color)"
                            : "initial",
                          color: selectedAirlins.includes(item.code)
                            ? "var(--white)"
                            : "var(--secondary-color)",
                        }}
                      >
                        <Box
                          sx={{
                            fontSize: { xs: 11, md: 14 },
                          }}
                        >
                          {item.code}
                        </Box>
                        <Box sx={{ width: "25px" }}>
                          <img
                            src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${item.code}.png`}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              border: "1px solid var(--text-color)",
                              borderRadius: "50%",
                              padding: "2px",
                            }}
                          />
                        </Box>
                        <Stack direction="column">
                          <Box
                            sx={{
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                          >
                            {commaNumber(item.price)}{" "}
                            <span style={{ fontSize: "10px" }}>BDT</span>
                          </Box>
                        </Stack>
                      </Stack>
                    </Tooltip>
                  </div>
                </label>
              </Box>
            );
          })}
        </Slider>
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: { xs: -2, md: -1 },
          top: "50%",
          transform: "translateY(-50%)",
          display: uniqueCarriers.length > 6 ? "flex" : "none",
          height: "100%",
        }}
      >
        <button onClick={goToPrev}>
          <ArrowForwardIosRoundedIcon
            sx={{ transform: "rotate(180deg)", fontSize: 16, py: 0.1 }}
          />
        </button>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: { xs: -3, md: -2 },
          top: "50%",
          transform: "translateY(-50%)",
          display: uniqueCarriers.length > 6 ? "flex" : "none",
          height: "100%",
        }}
      >
        <button onClick={goToNext}>
          <ArrowForwardIosRoundedIcon sx={{ fontSize: 16, py: 0.1 }} />
        </button>
      </Box>
    </Box>
    // <Box
    //   sx={{
    //     background: "#FFFFFF",
    //     boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    //     borderRadius: "10px",
    //     px: "30px",
    //     overflow: "hidden",
    //   }}
    // >
    //   <div className="slider-container">
    //     <Slider {...settings}>
    //       {airlineNames?.map((flight, index) => (
    //         <div key={index}>
    //           <Box
    //             sx={{
    //               display: "flex",
    //               alignItems: "center",
    //               msLineBreak: "30px",
    //               // width: "90%",
    //               p: "5px",
    //               // mx: "30px",
    //               gap: "10px",
    //               ":hover": {
    //                 background: "#FFE9BD",
    //                 cursor: "pointer",
    //                 width: "100%",
    //               },
    //             }}
    //             onClick={() => onAirlineClick(flight?.career)}
    //           >
    //             <img
    //               src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flight?.career}.png`}
    //               alt={flight?.careerName}
    //               style={{ width: "40px", height: "40px" }}
    //             />
    //             <Box>
    //               <Typography sx={{ color: "#B6B6CC", fontSize: "12px" }}>
    //                 {flight?.career}
    //               </Typography>
    //               <Typography sx={{ color: "#3C4258", fontSize: "15px" }}>
    //                 ৳ {flight?.clientPrice}
    //               </Typography>
    //             </Box>
    //           </Box>
    //         </div>
    //       ))}
    //     </Slider>
    //   </div>
    // </Box>
  );
};

export default AirlinesSlider;
