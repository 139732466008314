import { Box, Checkbox, Slider, Typography } from "@mui/material";
import React, { useState } from "react";

const TourFilter = () => {
  const [pricevalue, setPriceValue] = useState([20, 37]);

  const handleChange = (event, newValue) => {
    setPriceValue(newValue);
  };
  const [checked, setChecked] = React.useState(true);

  const handleChanges = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <Box sx={{ p: "10px 15px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #D9D9D9",
          pb: "2px",
        }}
      >
        <Typography sx={{ fontSize: "16px", color: "#3D3A49" }}>
          Filter
        </Typography>
        <Typography sx={{ color: "#63686D" }}>Reset</Typography>
      </Box>

      <Box sx={{ py: "20px" }}>
        <Typography sx={{ fontSize: "15px", color: "#3D3A49" }}>
          Price Range
        </Typography>
        <Box sx={{ pt: "15px" }}>
          <Slider
            style={{ color: "var(--primary-color)", fontSize: "10px" }}
            getAriaLabel={() => "Temperature range"}
            value={pricevalue}
            onChange={handleChange}
            valueLabelDisplay="auto"
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "var(--meta-black)",
                fontWeight: 500,
              }}
            >
              ৳ 5,500
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "var(--meta-black)",
                fontWeight: 500,
              }}
            >
              ৳ 55,500
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: "10px" }}>
        <Box>
          <Typography
            sx={{
              color: "var(--filter-text-color)",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Fare Type
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              pt: "3px",
            }}
          >
            <Box sx={{ p: "0", m: "0" }}>
              <Checkbox
                style={{ color: "var(--primary-button)" }}
                checked={checked}
                onChange={handleChanges}
                inputProps={{ "aria-label": "controlled" }}
              />{" "}
            </Box>
            <Typography sx={{ color: "Var(--filter-color)" }}>
              Refundable
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              pt: "3px",
            }}
          >
            <Box sx={{ p: "0", m: "0" }}>
              <Checkbox
                style={{ color: "var(--primary-button)" }}
                checked={checked}
                onChange={handleChanges}
                inputProps={{ "aria-label": "controlled" }}
              />{" "}
            </Box>
            <Typography sx={{ color: "Var(--filter-color)" }}>
              Non Refundable
            </Typography>
          </Box>

          <Box sx={{ pt: "10px", px: "10px" }}>
            <Typography
              sx={{
                borderBottom: "1px solid #D9D9D9 !important",
                width: "60px",
                color: "Var(--filter-color)",
                fontSize: "13px",
              }}
            >
              See More
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: "20px" }}>
        <Box>
          <Typography
            sx={{
              color: "var(--filter-text-color)",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Tour Type
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              pt: "3px",
            }}
          >
            <Box sx={{ p: "0", m: "0" }}>
              <Checkbox
                style={{ color: "var(--primary-button)" }}
                checked={checked}
                onChange={handleChanges}
                inputProps={{ "aria-label": "controlled" }}
              />{" "}
            </Box>
            <Typography sx={{ color: "Var(--filter-color)" }}>
              Couple Friendly
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              pt: "3px",
            }}
          >
            <Box sx={{ p: "0", m: "0" }}>
              <Checkbox
                style={{ color: "var(--primary-button)" }}
                checked={checked}
                onChange={handleChanges}
                inputProps={{ "aria-label": "controlled" }}
              />{" "}
            </Box>
            <Typography sx={{ color: "Var(--filter-color)" }}>
              Family Friendly
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              pt: "3px",
            }}
          >
            <Box sx={{ p: "0", m: "0" }}>
              <Checkbox
                style={{ color: "var(--primary-button)" }}
                checked={checked}
                onChange={handleChanges}
                inputProps={{ "aria-label": "controlled" }}
              />{" "}
            </Box>
            <Typography sx={{ color: "Var(--filter-color)" }}>
              Children Friendly
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TourFilter;
