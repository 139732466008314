import { Box, CircularProgress, Typography, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const isMobile = window.innerWidth <= 768;

const UserWallet = () => {
  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3); // Number of items per page
  const token = secureLocalStorage.getItem("access_token");
  const user = secureLocalStorage.getItem("userData");
  const navigate = useNavigate();
  const handleAddDepositClick = () => {
    navigate("/userinfov2/adddeposite");
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          "https://flyitsearch-backend-api-539319089408.asia-east1.run.app/deposit/user/findAll",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setWalletData(data||[]);
        } else {
          console.error("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [token]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = walletData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(walletData.length / itemsPerPage);



  return (
    <Box>
      <Box
        sx={{
          bgcolor: "var(--primary-color)",
          borderRadius: "3px",
          px: "30px",
          display: "flex",
          alignItems: "center",
          py: "10px",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={handleAddDepositClick}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: {
              xs: "12px",
              sm: "12px",
              md: "14px",
              lg: "14px",
            },
          }}
        >
          Add Deposit
        </Typography>
        <AddIcon sx={{ fontWeight: 600 }} />
      </Box>

      <Box
        sx={{
          p: {
            xs: "15px",
            // sm: "20px 30px 20px 40px",
            md: "20px 30px",
            // lg: "30px 30px 40px 40px",
          },
          bgcolor: "white",
          mt: "15px",
          borderRadius: "5px",
        }}
      >
        {   loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <CircularProgress />
        </Box>
      ):
        
        currentItems?.length > 0 ? (
          currentItems?.map((wallet, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #D9D9D9",
                  mb: 1,
                  p: 1,
borderRadius: "5px",                }}
              >
                <Box>
                  <Box
                    sx={{
                      bgcolor: "var(--primary-button)",
                      py: "5px",
                      px: "10px",
                      // height: "20px",
                      width: {
                        xs: "fit-content",
                        // sm: "80%",
                        md: "fit-content",
                        lg: "fit-content",
                      },
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "13px",
                          lg: "13px",
                        },
                      }}
                    >
                      Deposit ID: {wallet?.depositId}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "var(--primary-button)",
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "13px",
                          lg: "13px",
                        },
                        fontWeight: 600,
                        pt: "10px",
                      }}
                    >
                      BDT {wallet?.ammount} {wallet?.depositType}, Request by{" "}
                      {""}
                      {user?.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--meta-black)",
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "12px",
                          lg: "12px",
                        },
                        fontWeight: 500,
                        pt: "5px",
                      }}
                    >
                      Request at:{" "}
                      {moment(wallet?.createdAt).format("ddd, DD MMM hh:mm A")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--meta-black)",
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "12px",
                          lg: "12px",
                        },
                        fontWeight: 500,
                        pt: "5px",
                      }}
                    >
                      Status:{" "}
                      <span
                        style={{
                          color:
                            wallet?.status === "Approved"
                              ? "green"
                              : wallet?.status === "Pending"
                              ? "var(--primary-button)"
                              : "red",
                          fontSize: isMobile ? "11px" : "12px",
                          fontWeight: 600,
                        }}
                      >
                        {" "}
                        {wallet?.status}
                      </span>{" "}
                      {wallet?.status === "Rejected" && (
                        <>
                          <span style={{ paddingLeft: "5px" }}> Reason :</span>
                          <span
                            style={{
                              fontSize: isMobile ? "11px" : "13px",
                              fontWeight: 600,

                              textTransform: "capitalize",
                            }}
                          >
                            {wallet?.rejectionReason}
                          </span>
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography sx={{ color: "var(--meta-black)", textAlign: "center" }}>
            No Deposit available.
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          color="secondary"
          sx={{ mt: 3, display: "flex", justifyContent: "center" }}
          size="small"
        />
      </Box>
    </Box>
  );
};

export default UserWallet;
