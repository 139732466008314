import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import bannerImg from "../../asstes/img/HomeImage/banner.png";
import FlightSearchBox from "../HomeSearchBox/FlightSearchBox";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { addDays, format } from "date-fns";
import { useRef } from "react";
import { Link } from "react-router-dom";
const isMobile = window.innerWidth <= 768;

const Banner = () => {
  const banner = {
    background: `url(${bannerImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "330px",
  };

  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect

  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  const [changeTo, setChangeTo] = useState(false);
  //todo: End state for from date change
  const [valueBox, setValueBox] = useState("oneway");
  const [type, setType] = useState("tour");

  const [fromSearchText, setFromSearchText] = useState(
    "Hazrat Shahjalal Intl Airport(DAC)"
  );
  const [toSearchText, setToSearchText] = useState("Cox's Bazar Airport(CXB)");

  const [departureDate, setDepartureDate] = useState(
    format(addDays(new Date(), 1), "dd MMM yy")
  );
  const [returningDate, setReturningDate] = useState(
    format(addDays(new Date(departureDate), 3), "dd MMM yy")
  );
  const [travelDate, setTravelDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 3),
      key: "selection",
    },
  ]);
  // for multiCity
  const now = useRef(new Date());
  const [from, setFrom] = useState(addDays(now.current, 1));
  const [to, setTo] = useState(addDays(now.current, 3));

  const [faddress, setfaddress] = useState("Dhaka,BANGLADESH");
  const [toAddress, setToAddress] = useState("Cox's Bazar,Bangladesh");
  const [fromSendData, setFromSendData] = useState("DAC");
  const [toSendData, setToSendData] = useState("CXB");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infant, setInfant] = useState(0);

  console.log("adultCount,childCount,infant", adultCount, childCount, infant);
  const [result, setResult] = useState(1);
  const [className, setClassName] = useState("1");

  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };

  // const handleChangess = (event, newValue) => {
  //   setValueBox(newValue);
  // };
  const handleClassName = (event, newValue) => {
    setClassName(newValue);
  };

  const [searchData, setSearchData] = useState({
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    CityCount: 2,
    segments: [
      {
        id: 0,
        openFrom: false,
        DepFrom: fromSendData.trim(),
        depFromText: fromSearchText,
        ArrTo: toSendData.trim(),
        arrToText: toSearchText,
        openTo: false,
        Date: new Date().toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
      {
        id: 1,
        openFrom: false,
        DepFrom: toSendData.trim(),
        depFromText: toSearchText,
        ArrTo: "DXB",
        arrToText: "Dubai Intl Airport (DXB)",
        openTo: false,
        Date: new Date().toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
    ],
  });

  const [value, setValue] = useState("flight");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box style={banner}>
        <Container>
          <TabContext value={value}>
            <Box sx={{ pt: "40px" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                indicatorColor="none"
                textColor="white"
              >
                <Tab
                  label="Flight"
                  value="flight"
                  sx={{
                    textTransform: "Capitalize",
                    color: "var(--white)",
                    opacity: "1",
                    width: "0%",
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--button-color)",
                      color: "var(--primary-text-color)",
                      borderRadius: "5px",
                      opacity: "1",
                      width: { lg: "110px", xs: "fit-content" },
                      minHeight: "45px",
                    },
                    fontSize: { xs: "10px", sm: "10px", md: "14px" },
                    fontWeight: 600,
                  }}
                />
                <Tab
                  label="Hotel"
                  value="hotel"
                  style={{ marginLeft: "5px" }}
                  sx={{
                    textTransform: "Capitalize",
                    color: "var(--white)",
                    opacity: "1",
                    borderRadius: "5px",
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--button-color)",
                      color: "var(--primary-text-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    width: { lg: "110px", xs: "fit-content" },
                    minHeight: "45px",
                    fontWeight: 600,
                    margin: { xs: "0px 0px" },
                    fontSize: { xs: "10px", sm: "10px", md: "14px" },
                  }}
                />
                <Tab
                  label="Airport Taxi"
                  value="airportTaxi"
                  style={{ marginLeft: "5px" }}
                  sx={{
                    textTransform: "Capitalize",
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--button-color)",
                      color: "var(--primary-text-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    color: "var(--white)",
                    opacity: "1",
                    borderRadius: "5px",
                    width: { lg: "120px", xs: "fit-content" },
                    fontWeight: 600,
                    minHeight: "45px",
                    margin: { xs: "0px 0px" },
                    fontSize: { xs: "10px", sm: "10px", md: "14px" },
                  }}
                />
                <a href="/tour" style={{ textDecoration: "none" }}>
                  <Tab
                    label="Tour"
                    value="tour"
                    style={{ marginLeft: "5px" }}
                    sx={{
                      textTransform: "Capitalize",
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--button-color)",
                        color: "var(--primary-text-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontWeight: 600,
                      color: "var(--white)",
                      opacity: "1",
                      borderRadius: "5px",
                      width: { lg: "100px", xs: "fit-content" },
                      minHeight: "45px",
                      margin: { xs: "0px 0px" },
                      fontSize: { xs: "10px", sm: "10px", md: "14px" },
                    }}
                  />
                </a>
              </TabList>
            </Box>
            {/* )} */}

            <Box
              sx={{ py: { xs: "15px", sm: "15px", md: "50px", lg: "50px" } }}
            >
              <Typography
                sx={{
                  color: "var(--white)",
                  fontWeight: 600,
                  fontSize: {
                    xs: "28px",
                    sm: "28px",
                    md: "40px",
                    lg: "40px",
                  },
                }}
              >
                Discover Great Flight Deals.
              </Typography>
              <Typography
                sx={{
                  color: "var(--white)",
                  fontWeight: 500,
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "18px",
                    lg: "18px",
                  },
                }}
              >
                Book your Flight, Hotels and Desire Holiday Packages
              </Typography>
            </Box>
          </TabContext>
        </Container>
      </Box>
      <Container
        sx={{
          mt: -8,
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              bgcolor: "var(--white)",
              border: "1px solid var(--button-color)",
              borderRadius: "5px",
              padding: "10px 20px",
            }}
          >
            <TabPanel value="flight" sx={{ padding: "0px" }}>
              <FlightSearchBox
                type={type}
                valueBox={valueBox}
                setValueBox={setValueBox}
                setType={setType}
                value={value}
                setValue={setValue}
                fromSearchText={fromSearchText}
                setFromSearchText={setFromSearchText}
                toSearchText={toSearchText}
                setToSearchText={setToSearchText}
                departureDate={departureDate}
                setDepartureDate={setDepartureDate}
                returningDate={returningDate}
                setReturningDate={setReturningDate}
                travelDate={travelDate}
                setTravelDate={setTravelDate}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                faddress={faddress}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                fromSendData={fromSendData}
                setFromSendData={setFromSendData}
                toSendData={toSendData}
                setToSendData={setToSendData}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                setInfant={setInfant}
                result={result}
                setResult={setResult}
                className={className}
                setClassName={setClassName}
                handleClassName={handleClassName}
                setChangeState={setChangeState}
                changeState={changeState}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
                changeTo={changeTo}
                setChangeTo={setChangeTo}
                searchData={searchData}
                setSearchData={setSearchData}
              />
            </TabPanel>
            <TabPanel value="hotel" sx={{ width: "100%", padding: "0px" }}>
              <Typography>
                For Development Reason Hotel will be disable Thank you for your
                Cooperation.
              </Typography>
            </TabPanel>
            <TabPanel
              value="airportTaxi"
              sx={{ width: "100%", padding: "0px" }}
            >
              <Typography>
                For Development Reason Airport Taxi will be disable Thank you
                for your Cooperation.
              </Typography>
            </TabPanel>
            <TabPanel value="tour" sx={{ width: "100%", padding: "0px" }}>
              <Typography>Tour Coming...Soon</Typography>
            </TabPanel>
          </Box>
        </TabContext>
      </Container>
    </Box>
  );
};

export default Banner;
