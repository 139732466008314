import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import tourDes1 from "../../../asstes/img/tour/allpack/tourDes1.png";
import tourDes2 from "../../../asstes/img/tour/allpack/tourDes2.png";
import tourDes3 from "../../../asstes/img/tour/allpack/tourDes3.png";
import tourDes4 from "../../../asstes/img/tour/allpack/tourDes4.png";
import tourDes5 from "../../../asstes/img/tour/allpack/tourDes5.png";
import tourDes6 from "../../../asstes/img/tour/allpack/tourDes6.png";
import tourDes7 from "../../../asstes/img/tour/allpack/tourDes7.png";
import tourDes8 from "../../../asstes/img/tour/allpack/tourDes8.png";
import tourDes9 from "../../../asstes/img/tour/allpack/tourDes9.png";
import tourDes10 from "../../../asstes/img/tour/allpack/tourDes10.png";
import tourDes11 from "../../../asstes/img/tour/allpack/tourDes11.png";
import tourDes12 from "../../../asstes/img/tour/allpack/tourDes12.png";
import tourDes13 from "../../../asstes/img/tour/allpack/tourDes13.png";
import tourDes14 from "../../../asstes/img/tour/allpack/tourDes14.png";
import tourDes15 from "../../../asstes/img/tour/allpack/tourDes15.png";

const AllPack = () => {
  return (
    <Box sx={{ py: "30px" }}>
      <Grid container  rowSpacing={2} columnSpacing={12}>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes1} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes2} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes3} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes4} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes5} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes6} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes7} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes8} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes9} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes10} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes11} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes12} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes13} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes14} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2.4}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img src={tourDes15} />
            </Box>
            <Box sx={{ position: "absolute", bottom: "10%", p: "0px 15px" }}>
              <Typography sx={{ color: "var(--white)" }}>Singapore</Typography>
              <Typography sx={{ color: "var(--white)" }}>
                14 things to do
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllPack;
