import { TabContext, TabPanel } from "@mui/lab";
import { Box, Collapse, Tab, Tabs } from "@mui/material";
import React from "react";
import OneWayFlightDetils from "../OneWayFlightDetils/OneWayFlightDetils";
import FareDetails from "../OneWayFlightDetils/FareDetails";
import FarePolicy from "../OneWayFlightDetils/FarePolicy";
import Baggage from "../OneWayFlightDetils/Baggage";
import Cancellation from "../OneWayFlightDetils/Cancellation";
import DateChange from "../OneWayFlightDetils/DateChange";
import RoundwayFlightDetails from "../RoundwayFlightDetails/RoundwayFlightDetails";
import MulticityFlightDetails from "../MultiCity/MulticityFlightDetails/MulticityFlightDetails";
import MulticityBaggage from "../MultiCity/MulticityFlightDetails/MulticityBaggage";
import FlightDetails from "./FlightDetails";

const ShowDetails = ({
  data,
  flightDetails,
  value,
  handleChange,
  round,
  multicity,
}) => {
  const isMobile = window.innerWidth <= 768;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        m: "0px auto",
        // mt: 2,
      }}
    >
      <Collapse
        in={flightDetails}
        timeout="auto"
        unmountOnExit
        sx={{
          width: "100%",
          bgcolor: "var(--white)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // borderTop: "1px solid #D9E7F2",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            mt: 2,
          }}
        >
          <TabContext value={value}>
            <Box
              sx={{
                flexGrow: 1,
                width: { xs: "300px", sm: "fit-content" },
                background: "var(--primary-color)",
                height: {
                  lg: "35px",
                  md: "35px",
                  sm: "30px",
                  xs: "30px",
                },
                minHeight: "100%",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: "1",
                borderRadius: "0px 3px 3px 0px",

                ".MuiTabs-flexContainer": {
                  flexWrap: { xs: "nowrap", sm: "nowrap", md: "wrap" },
                  padding: {
                    lg: "0px",
                    md: "0px",
                    sm: "0px 10px",
                    xs: "0px 10px",
                  },
                },
                "& button": {
                  color: "var(--meta-black)",
                  opacity: "1",
                  borderBottom: "0",
                  textTransform: "capitalize",
                  padding: {
                    lg: "0 15px",
                    md: "0 15px",
                  },
                  fontSize: { xs: "10px", sm: "12px", md: "12px" },
                },

                "& button.Mui-selected": {
                  background: "var(--primary-button)",
                  color: "var(--white)",
                  opacity: "1",
                },
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab label="Flight Details" value="1" />
                <Tab label="Fare Details" value="2" />
                <Tab label="Baggage" value="5" />
                <Tab label="cancellation " value="6" />
                <Tab label="Date Change" value="7" />
                <Tab
                  sx={{
                    display: {
                      // xs: "none",
                      // sm: "none",
                      md: "block",
                      lg: "block",
                    },
                  }}
                  label="Fare Policy"
                  value="4"
                />
              </Tabs>
            </Box>
            {/* //todo:Flight Details */}
            <TabPanel
              value="1"
              style={{
                padding: "10px 20px",
                background: "#EBF0F4",
                borderRadius: "5px",
              }}
              sx={{
                width: { xs: "95%", md: "98%" },
              }}
            >
              <FlightDetails data={data} />
              {/* {multicity ? (
                <MulticityFlightDetails data={data} />
              ) : round ? (
                <RoundwayFlightDetails data={data} />
              ) : (
                <OneWayFlightDetils data={data} />
              )} */}
            </TabPanel>
            {/* //todo:Fare Details */}
            <TabPanel
              value="2"
              style={{
                padding: isMobile ? "10px 10px" : "10px 20px",
                background: "#EBF0F4",
                borderRadius: "5px",
              }}
              sx={{
                width: { xs: "95%", md: "98%" },
              }}
            >
              <FareDetails data={data} />
            </TabPanel>
            {/*  //todo: Policy*/}
            <TabPanel
              value="4"
              style={{
                padding: "10px 20px",
                background: "#EBF0F4",
                borderRadius: "5px",
              }}
              sx={{
                width: { xs: "95%", md: "98%" },
              }}
            >
              <Box>
                <FarePolicy
                  searchId={data?.SearchId}
                  resultId={data?.ResultId}
                />{" "}
              </Box>
            </TabPanel>

            {/*  //todo: Baggage*/}
            <TabPanel
              value="5"
              style={{
                padding: "10px 20px",
                background: "#EBF0F4",
                borderRadius: "5px",
              }}
              sx={{
                width: { xs: "95%", md: "98%" },
              }}
            >
              <Box>
                {/* {multicity ? (
                  <MulticityBaggage data={data} />
                ) : (
                  <Baggage data={data} />
                )} */}
                <MulticityBaggage data={data} />
              </Box>
            </TabPanel>
            {/* cancellation   */}
            <TabPanel
              value="6"
              style={{
                padding: "10px 20px",
                background: "#EBF0F4",
                borderRadius: "5px",
              }}
              sx={{
                width: { xs: "95%", md: "98%" },
              }}
            >
              <Box>
                <Cancellation
                  searchId={data?.SearchId}
                  resultId={data?.ResultId}
                />{" "}
              </Box>
            </TabPanel>
            {/* Date Change   */}
            <TabPanel
              value="7"
              style={{
                padding: "10px 20px",
                background: "#EBF0F4",
                borderRadius: "5px",
              }}
              sx={{
                width: { xs: "95%", md: "98%" },
              }}
            >
              <Box>
                <DateChange
                  searchId={data?.SearchId}
                  resultId={data?.ResultId}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ShowDetails;
