import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import bestDestination1 from "../../../asstes/img/tour/destinataino/best1.png";
import bestDestination2 from "../../../asstes/img/tour/destinataino/best2.png";
import bestDestination3 from "../../../asstes/img/tour/destinataino/best3.png";
import bestDestination4 from "../../../asstes/img/tour/destinataino/best4.png";
import bestDestination5 from "../../../asstes/img/tour/destinataino/best5.png";
import bestDestination6 from "../../../asstes/img/tour/destinataino/best6.png";
import bestDestination7 from "../../../asstes/img/tour/destinataino/best7.png";
import bestDestination8 from "../../../asstes/img/tour/destinataino/best8.png";
const TourBestDestination = () => {
  return (
    <Box sx={{ my: "130px" }}>
      <Container>
        <Typography
          sx={{ fontSize: "40px", color: "var(--primary-text-color)" }}
        >
          Best Destinations
        </Typography>
        <Grid container rowSpacing={4} columnSpacing={10} sx={{ pt: "30px" }}>
          <Grid item md={3}>
            <Box sx={{ position: "relative" }}>
              <Box>
                <img src={bestDestination1} />{" "}
              </Box>
              <Box sx={{ position: "absolute", bottom: "10%", p: "0px 20px" }}>
                <Typography sx={{ color: "var(--white)", fontSize: "18px" }}>
                  Singapore
                </Typography>
                <Typography
                  sx={{ color: "var(--white)", fontSize: "14px", pt: "7px" }}
                >
                  Starts From
                </Typography>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "16px",
                    fontWeight: 600,
                    pt: "5px",
                  }}
                >
                  BDT 50,000
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box sx={{ position: "relative" }}>
              <Box>
                <img src={bestDestination2} />{" "}
              </Box>
              <Box sx={{ position: "absolute", bottom: "10%", p: "0px 20px" }}>
                <Typography sx={{ color: "var(--white)", fontSize: "18px" }}>
                  Singapore
                </Typography>
                <Typography
                  sx={{ color: "var(--white)", fontSize: "14px", pt: "7px" }}
                >
                  Starts From
                </Typography>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "16px",
                    fontWeight: 600,
                    pt: "5px",
                  }}
                >
                  BDT 50,000
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box sx={{ position: "relative" }}>
              <Box>
                <img src={bestDestination3} />{" "}
              </Box>
              <Box sx={{ position: "absolute", bottom: "10%", p: "0px 20px" }}>
                <Typography sx={{ color: "var(--white)", fontSize: "18px" }}>
                  Singapore
                </Typography>
                <Typography
                  sx={{ color: "var(--white)", fontSize: "14px", pt: "7px" }}
                >
                  Starts From
                </Typography>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "16px",
                    fontWeight: 600,
                    pt: "5px",
                  }}
                >
                  BDT 50,000
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box sx={{ position: "relative" }}>
              <Box>
                <img src={bestDestination4} />{" "}
              </Box>
              <Box sx={{ position: "absolute", bottom: "10%", p: "0px 20px" }}>
                <Typography sx={{ color: "var(--white)", fontSize: "18px" }}>
                  Singapore
                </Typography>
                <Typography
                  sx={{ color: "var(--white)", fontSize: "14px", pt: "7px" }}
                >
                  Starts From
                </Typography>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "16px",
                    fontWeight: 600,
                    pt: "5px",
                  }}
                >
                  BDT 50,000
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box sx={{ position: "relative" }}>
              <Box>
                <img src={bestDestination5} />{" "}
              </Box>
              <Box sx={{ position: "absolute", bottom: "10%", p: "0px 20px" }}>
                <Typography sx={{ color: "var(--white)", fontSize: "18px" }}>
                  Singapore
                </Typography>
                <Typography
                  sx={{ color: "var(--white)", fontSize: "14px", pt: "7px" }}
                >
                  Starts From
                </Typography>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "16px",
                    fontWeight: 600,
                    pt: "5px",
                  }}
                >
                  BDT 50,000
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box sx={{ position: "relative" }}>
              <Box>
                <img src={bestDestination6} />{" "}
              </Box>
              <Box sx={{ position: "absolute", bottom: "10%", p: "0px 20px" }}>
                <Typography sx={{ color: "var(--white)", fontSize: "18px" }}>
                  Singapore
                </Typography>
                <Typography
                  sx={{ color: "var(--white)", fontSize: "14px", pt: "7px" }}
                >
                  Starts From
                </Typography>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "16px",
                    fontWeight: 600,
                    pt: "5px",
                  }}
                >
                  BDT 50,000
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box sx={{ position: "relative" }}>
              <Box>
                <img src={bestDestination7} />{" "}
              </Box>
              <Box sx={{ position: "absolute", bottom: "10%", p: "0px 20px" }}>
                <Typography sx={{ color: "var(--white)", fontSize: "18px" }}>
                  Singapore
                </Typography>
                <Typography
                  sx={{ color: "var(--white)", fontSize: "14px", pt: "7px" }}
                >
                  Starts From
                </Typography>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "16px",
                    fontWeight: 600,
                    pt: "5px",
                  }}
                >
                  BDT 50,000
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box sx={{ position: "relative" }}>
              <Box>
                <img src={bestDestination8} />{" "}
              </Box>
              <Box sx={{ position: "absolute", bottom: "10%", p: "0px 20px" }}>
                <Typography sx={{ color: "var(--white)", fontSize: "18px" }}>
                  Singapore
                </Typography>
                <Typography
                  sx={{ color: "var(--white)", fontSize: "14px", pt: "7px" }}
                >
                  Starts From
                </Typography>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "16px",
                    fontWeight: 600,
                    pt: "5px",
                  }}
                >
                  BDT 50,000
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TourBestDestination;
