import { Box, Typography } from "@mui/material";
import React from "react";
import SignUp from "../SignUp/SignUp";
import Banner from "./Banner";
import NavBar from "../../Shared/NavBar/NavBar";
import DiscountSlider from "./DiscountSlider";
import HotDeals from "../HotDeals/HotDeals";
import RecentSearchBar from "./RecentSearchBar";
import ChatBox from "../ChatBox/ChatBox";

const Home = () => {
  return (
    <Box>
      <NavBar />
      <Banner />
      {/* <RecentSearchBar/> */}
      <DiscountSlider />
      <HotDeals />
    </Box>
  );
};

export default Home;
