import { Box, Typography } from "@mui/material";
import { ReactComponent as TimimngIcons } from "../../../asstes/img/timing.svg";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

const TimeSesation = () => {
  const [timeRemaining, setTimeRemaining] = useState(900);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeRemaining <= 0) {
      MySwal.fire({
        title: "Your Session Time Is Expired",
        icon: "warning",
        confirmButtonText: "Search Again",
        confirmButtonColor: "var(--primary-color)",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
      return;
    }

    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeRemaining]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Box sx={{ bgcolor: "#FFFFFF", py: "20px", px: "15px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Box>
          <Typography
            sx={{
              fontSize: "16px",
              color: "var(--primary-button)",
              fontWeight: 600,
            }}
          >
            Time Remaining {formatTime(timeRemaining)}
          </Typography>
          <Typography sx={{ fontSize: "12px", color: "#71727C", pt: "5px" }}>
            For security reasons, your session will close automatically.
          </Typography>
        </Box>
        <Box>
          <TimimngIcons />
        </Box>
      </Box>
    </Box>
  );
};

export default TimeSesation;
