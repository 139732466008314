import React from "react";
import { Box, Typography } from "@mui/material";
import img1 from "../../asstes/img/hotDeals/img1.webp";
import img2 from "../../asstes/img/hotDeals/img2.jpeg";
import img3 from "../../asstes/img/hotDeals/img4.webp";
import img4 from "../../asstes/img/hotDeals/imag4.webp";
import img5 from "../../asstes/img/hotDeals/img5.jpeg";
import img6 from "../../asstes/img/hotDeals/img6.jpeg";
import img7 from "../../asstes/img/hotDeals/img7.jpeg";
import img8 from "../../asstes/img/hotDeals/img8.jpeg";
import img9 from "../../asstes/img/hotDeals/d2ViX0dldCBCZXN0IEhvdGVsIERlYWxz.jpg";
import img10 from "../../asstes/img/hotDeals/img10.webp";
import img11 from "../../asstes/img/hotDeals/im11.jpeg";
import img12 from "../../asstes/img/hotDeals/img12.jpeg";
import img13 from "../../asstes/img/hotDeals/img13.jpeg";
import Slider from "react-slick";

const GroupFareDeals = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <Box
            sx={{
              bgcolor: "var(--white)",
              height: { xs: "100%", sm: "100%", md: "350px", lg: "350px" },
              ml: "20px",
              position: "relative",
              p: "12px",
              borderRadius: "5px",
            }}
          >
            <Box>
              <img
                style={{ width: "100%", height: "100%", borderRadius: "3px" }}
                src={img6}
              />
            </Box>
            <Box
              sx={{
                bgcolor: "var(--primary-button)",
                color: "var(--white)",
                height: "30px",
                width: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "5%",
                right: "8%",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>Group Fare</Typography>
            </Box>
            <Box sx={{ py: "10px" }}>
              <Typography
                sx={{
                  color: "var(--meta-black)",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Per Day Deal
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  pt: "5px",
                  color: "var(--filter-color)",
                }}
              >
                The concept of a "Per Day Deal" presents an enticing opportunity
                for savvy shoppers and budget-conscious individuals to
                capitalize on exclusive discounts and savings.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", pt: "15px" }}
            >
              <Box
                sx={{
                  bgcolor: "var(--button-color)",
                  color: "var(--white)",
                  height: "30px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "13px" }}>See More</Typography>
              </Box>
            </Box>
          </Box>
        </div>

        <div>
          <Box
            sx={{
              bgcolor: "var(--white)",
              height: { xs: "100%", sm: "100%", md: "350px", lg: "350px" },
              ml: "20px",
              position: "relative",
              p: "12px",
              borderRadius: "5px",
            }}
          >
            <Box>
              <img
                style={{ width: "100%", height: "100%", borderRadius: "3px" }}
                src={img6}
              />
            </Box>
            <Box
              sx={{
                bgcolor: "var(--primary-button)",
                color: "var(--white)",
                height: "30px",
                width: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "5%",
                right: "8%",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>Group Fare</Typography>
            </Box>
            <Box sx={{ py: "10px" }}>
              <Typography
                sx={{
                  color: "var(--meta-black)",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Per Day Deal
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  pt: "5px",
                  color: "var(--filter-color)",
                }}
              >
                The concept of a "Per Day Deal" presents an enticing opportunity
                for savvy shoppers and budget-conscious individuals to
                capitalize on exclusive discounts and savings.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", pt: "15px" }}
            >
              <Box
                sx={{
                  bgcolor: "var(--button-color)",
                  color: "var(--white)",
                  height: "30px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "13px" }}>See More</Typography>
              </Box>
            </Box>
          </Box>
        </div>

        <div>
          <Box
            sx={{
              bgcolor: "var(--white)",
              height: { xs: "100%", sm: "100%", md: "350px", lg: "350px" },
              ml: "20px",
              position: "relative",
              p: "12px",
              borderRadius: "5px",
            }}
          >
            <Box>
              <img
                style={{ width: "100%", height: "100%", borderRadius: "3px" }}
                src={img11}
              />
            </Box>
            <Box
              sx={{
                bgcolor: "var(--primary-button)",
                color: "var(--white)",
                height: "30px",
                width: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "5%",
                right: "8%",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>Group Fare</Typography>
            </Box>
            <Box sx={{ py: "10px" }}>
              <Typography
                sx={{
                  color: "var(--meta-black)",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Per Day Deal
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  pt: "5px",
                  color: "var(--filter-color)",
                }}
              >
                The concept of a "Per Day Deal" presents an enticing opportunity
                for savvy shoppers and budget-conscious individuals to
                capitalize on exclusive discounts and savings.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", pt: "15px" }}
            >
              <Box
                sx={{
                  bgcolor: "var(--button-color)",
                  color: "var(--white)",
                  height: "30px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "13px" }}>See More</Typography>
              </Box>
            </Box>
          </Box>
        </div>
        <div>
          <Box
            sx={{
              bgcolor: "var(--white)",
              height: { xs: "100%", sm: "100%", md: "350px", lg: "350px" },
              ml: "20px",
              position: "relative",
              p: "12px",
              borderRadius: "5px",
            }}
          >
            <Box>
              <img
                style={{ width: "100%", height: "100%", borderRadius: "3px" }}
                src={img6}
              />
            </Box>
            <Box
              sx={{
                bgcolor: "var(--primary-button)",
                color: "var(--white)",
                height: "30px",
                width: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "5%",
                right: "8%",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>Group Fare</Typography>
            </Box>
            <Box sx={{ py: "10px" }}>
              <Typography
                sx={{
                  color: "var(--meta-black)",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Per Day Deal
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  pt: "5px",
                  color: "var(--filter-color)",
                }}
              >
                The concept of a "Per Day Deal" presents an enticing opportunity
                for savvy shoppers and budget-conscious individuals to
                capitalize on exclusive discounts and savings.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", pt: "15px" }}
            >
              <Box
                sx={{
                  bgcolor: "var(--button-color)",
                  color: "var(--white)",
                  height: "30px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "13px" }}>See More</Typography>
              </Box>
            </Box>
          </Box>
        </div>
      </Slider>
    </div>
  );
};

export default GroupFareDeals;
