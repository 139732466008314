import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as HeadPhoneIcons } from "../../../asstes/img/hedpone.svg";
import { ReactComponent as MsgIcons } from "../../../asstes/img/msg.svg";
import { Link } from "react-router-dom";

const Support = () => {
  return (
    <Box sx={{ bgcolor: "#FFFFFF", py: "15px", px: "10px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <HeadPhoneIcons sx={{ fontSize: "15px" }} />
        <Typography sx={{ fontSize: "12px", color: "#71727C" }}>
          For any assistance visit{" "}
          <Link style={{ color: "var(--primary-button)" }}>support center</Link>
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", gap: "10px", pt: "10px" }}
      >
        <MsgIcons sx={{ fontSize: "15px" }} />
        <Typography sx={{ fontSize: "12px", color: "#71727C" }}>
          write to us{" "}
          <Link style={{ color: "var(--primary-button)" }}>support</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Support;
