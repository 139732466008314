import {
  Accordion,
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  Typography,
  Drawer,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AfterSearchBanner from "../AfterSearch/AfterSearchBanner";
import SingleOnewayFlight from "./SingleOnewayFlight";
import OneWayFilter from "../Filter/OneWayFilter";
import AirlinesSlider from "../AirlinesSlider/AirlinesSlider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import SingleOneWaySelekton from "./SingleOneWaySelekton";
import AirlinesSliderSlikton from "../AirlinesSlider/AirlinesSliderSlikton";
import secureLocalStorage from "react-secure-storage";
import NavBar from "../../Shared/NavBar/NavBar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { toFormData } from "axios";
import FlightSearchBox from "../HomeSearchBox/FlightSearchBox";
import RoundwaySelecton from "../Filter/RoundwaySelecton";
import Filter from "../Filter/Filter";
import { debounce } from "lodash";
import SingleFlight from "../SingleFlight/SingleFlight";
import Swal from "sweetalert2";
import FlightLoading from "./FlightLoading";
// import { FakeDataOneway } from "./FakeDataOneway";
const isMobile = window.innerWidth <= 768;

const OneWaySearchResult = () => {
  const navigate=useNavigate()
  const location = useLocation();
  const {
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    departureDate,
    arrivalDate,
    adultCount,
    childCount,
    infant,
    tripType,
    fromSendData,
    toSendData,
    className,
    result,
    valueBox,
    value,
    changeState,
    searchData,
    segments,
  } = location?.state || {};
  const [flights, setFlights] = useState([]);
  const [flight2, setFlight2] = useState([]);
  const [initialFetchData, setInitialFetchData] = useState([]);
  const [filteredFlights, setFilteredFlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAirline, setSelectedAirline] = useState(null);
  const token = secureLocalStorage.getItem("access_token");
  const [sortedBy, setSortedBy] = useState(null);

  // State for pagination
  
  const [currentPage, setCurrentPage] = useState(1);
  //Filter
  const [filterPageIndex, setFilterPageIndex] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [selectedStops, setSelectedStops] = useState([]);
  const [selectedAirlins, setSelectedAirlins] = useState([]);
  const [selectedRefundable, setSelectedRefundable] = useState([]);
  // const [filteredFlights, setFilteredFlights] = useState([]);
  const [selectedLayover, setSelectedLayover] = useState([]);
  const [selectedDepartTime, setSelectedDepartTime] = useState({
    name: "",
    type: "",
    startTime: "",
    endTime: "",
  });
  const [selectedArrivalTime, setSelectedArrivalTime] = useState({
    name: "",
    type: "",
    startTime: "",
    endTime: "",
  });
  const [selectedBackDepartTime, setSelectedBackDepartTime] = useState({
    name: "",
    type: "",
    startTime: "",
    endTime: "",
  });
  const [selectedBackArrivalTime, setSelectedBackArrivalTime] = useState({
    name: "",
    type: "",
    startTime: "",
    endTime: "",
  });

  const [selectedBaggage, setSelectedBaggage] = useState([]);
  const itemsPerPage = 15;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://flyitsearch-backend-api-539319089408.asia-east1.run.app/flights/fhb/airSearch",
          // "http://192.168.10.91:8080/flights/bdFare",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...(token && { Authorization: `Bearer ${token}` }),
            },
            body: JSON.stringify({
              adultcount: adultCount,
              childcount: childCount,
              infantcount: infant,
              cabinclass: className,
              segments: [
                {
                  depfrom: fromSendData,
                  arrto: toSendData,
                  depdate: moment(departureDate).format("YYYY-MM-DD"),
                },
              ],
            }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Network response was not ok");
        }
        const data = await response.json();
        if (data?.message === "Sign up to get more search") {
          throw new Error(data.message); // Handle "Sign up" message
        }
        setFlights(data || []);
        setFilteredFlights(data || []);
        setInitialFetchData(data || []);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message || "An unexpected error occurred.",
        }).then(() => {
          navigate("/"); // Redirect to the home page
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [adultCount, childCount, infant, fromSendData, toSendData, departureDate]);

  // const handleAirlineClick = (career) => {
  //   setSelectedAirline(career);
  //   const filtered = flights.filter((flight) => flight.Carrier === career);
  //   setFilteredFlights(filtered);
  //   setCurrentPage(1);
  // };

  // Handle Airline filter
  const handleAirlineClick = (career) => {
    if (selectedAirline === career) {
      setSelectedAirline(null);
      setFilteredFlights(flights);
    } else {
      setSelectedAirline(career);
      const filtered = flights.filter((flight) => flight.Carrier === career);
      setFilteredFlights(filtered);
    }
    setCurrentPage(1); // Reset pagination
  };

  const totalPages = Math.ceil(filteredFlights?.length / itemsPerPage);

  const paginatedFlights = filteredFlights?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModifiyDrawerOpen, setIsModifiyDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    setIsDrawerOpen(open);
  };
  const toggleModifiyDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsModifiyDrawerOpen(open);
  };

  //Filter
  const handleResetData = useCallback(() => {
    // setPageIndex(0);
    setFilterPageIndex(null);
    setSelectedProvider([]);
    setSelectedAirlins([]);
    setSelectedStops([]);
    setSelectedRefundable([]);
    setSelectedDepartTime({ name: "", type: "", startTime: "", endTime: "" });
    setSelectedArrivalTime({ name: "", type: "", startTime: "", endTime: "" });
    setSelectedBackDepartTime({
      name: "",
      type: "",
      startTime: "",
      endTime: "",
    });
    setSelectedBackArrivalTime({
      name: "",
      type: "",
      startTime: "",
      endTime: "",
    });
    setSelectedLayover([]);
    // Baggage Filter
    setSelectedBaggage([]);
    setFilteredFlights(flights);
    setCurrentPage(1); // Reset pagination
  }, [flights]);
  useEffect(() => {
    handleResetData();
  }, [flights]);
  // ----------------Functionality start ----------------
  // uniqueLayover
  const uniqueLayover = Array.from(
    new Set(
      flights
        ?.filter((item) => item.AllLegsInfo[0].Segments.length > 1)
        .map((item) => ({
          name:
            item.AllLegsInfo[0].Segments.length > 2
              ? item.AllLegsInfo[0].Segments[2].DepAirPort
              : item.AllLegsInfo[0].Segments[1].DepAirPort,
          code:
            item.AllLegsInfo[0].Segments.length > 2
              ? item.AllLegsInfo[0].Segments[2].DepFrom
              : item.AllLegsInfo[0].Segments[1].DepFrom,
        }))
        .map((entry) => JSON.stringify(entry))
    )
  ).map((str) => JSON.parse(str));

  const handleSelection = (name, setSelected) => {
    setSelected((prevSelected) => {
      const isSelected = prevSelected.includes(name);
      return isSelected
        ? prevSelected.filter((item) => item !== name)
        : [...prevSelected, name];
    });

    setCurrentPage(1);; // 2 or 3.. move page after filter setdata
  };

  const handleProvider = (name) => {
    handleSelection(name, setSelectedProvider);
  };
  const handleStops = (name) => {
    handleSelection(name, setSelectedStops);
  };
  const handleAirLine = (name, career) => {
    handleSelection(name, setSelectedAirlins);

    
  };
  const handleRefundable = (name) => {
    handleSelection(name, setSelectedRefundable);
  };
  // Date Filter
  const handleDepartTime = useCallback(
    (type, name, startTime, endTime) => {
      const startDate = new Date(`1970-01-01T${startTime}`);
      const endDate = new Date(`1970-01-01T${endTime}`);
      switch (type) {
        case "Depart":
          setSelectedDepartTime(
            name === selectedDepartTime.name
              ? { name: "", type: "", startTime: "", endTime: "" }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        case "Arrival":
          setSelectedArrivalTime(
            name === selectedArrivalTime.name
              ? { name: "", type: "", startTime: "", endTime: "" }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        // for roundway
        case "returnDepart":
          setSelectedBackDepartTime(
            name === selectedBackDepartTime.name
              ? { name: "", type: "", startTime: "", endTime: "" }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        case "returnArrival":
          setSelectedBackArrivalTime(
            name === selectedBackArrivalTime.name
              ? { name: "", type: "", startTime: "", endTime: "" }
              : { name, type: type, startTime: startDate, endTime: endDate }
          );
          break;
        default:
          break;
      }
    },
    [
      selectedDepartTime,
      selectedArrivalTime,
      selectedBackDepartTime,
      selectedBackArrivalTime,
    ]
  );

  const handleLayover = (name) => {
    handleSelection(name, setSelectedLayover);
  };

  // Baggage Filter
  const handleBaggage = (name) => {
    handleSelection(name, setSelectedBaggage);
  };

  const filter = () => {
    let updateData = flights; // Assuming 'data' is your original dataset
    // if (selectPrice.length > 0) {
    //   updateData = updateData.filter((item) =>
    //     selectPrice.includes(item.NetFare)
    //   );
    // }
    if (selectedProvider.length > 0) {
      updateData = updateData.filter((item) =>
        selectedProvider.includes(item.System)
      );
    }
    if (selectedStops.length > 0) {
      updateData = updateData.filter((item) =>
        selectedStops.includes(item.AllLegsInfo[0].Segments.length)
      );
    }
    if (selectedAirlins.length > 0) {
      updateData = updateData.filter((item) =>
        selectedAirlins.includes(item.Carrier)
      );
    }
    if (selectedRefundable.length > 0) {
      updateData = updateData.filter((item) =>
        selectedRefundable.includes(item.Refundable)
      );
    }

    if (
      selectedDepartTime.type === "Depart" &&
      selectedDepartTime.name.length > 0
    ) {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[0][0].DepTime.slice(
            11,
            19
          )}`
        );
        return (
          time >= selectedDepartTime.startTime &&
          time <= selectedDepartTime.endTime
        );
      });
    }

    if (selectedArrivalTime.type === "Arrival") {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[0][
            item.AllLegsInfo[0].Segments[0].length - 1
          ].ArrTime.slice(11, 19)}`
        );
        return (
          time >= selectedArrivalTime.startTime &&
          time <= selectedArrivalTime.endTime
        );
      });
    }

    if (selectedBackDepartTime.type === "returnDepart") {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[
            item.AllLegsInfo[0].Segments.length - 1
          ][
            item.AllLegsInfo[0].Segments[
              item.AllLegsInfo[0].Segments.length - 1
            ].length - 1
          ].DepTime.slice(11, 19)}`
        );
        return (
          time >= selectedBackDepartTime.startTime &&
          time <= selectedBackDepartTime.endTime
        );
      });
    }

    if (selectedBackArrivalTime.type === "returnArrival") {
      updateData = updateData.filter((item) => {
        const time = new Date(
          `1970-01-01T${item.AllLegsInfo[0].Segments[
            item.AllLegsInfo[0].Segments.length - 1
          ][
            item.AllLegsInfo[0].Segments[
              item.AllLegsInfo[0].Segments.length - 1
            ].length - 1
          ].ArrTime.slice(11, 19)}`
        );
        return (
          time >= selectedBackArrivalTime.startTime &&
          time <= selectedBackArrivalTime.endTime
        );
      });
    }

    if (selectedLayover.length > 0) {
      updateData = updateData.filter((item) => {
        const segments = item.AllLegsInfo[0].Segments;
        const DepFrom =
          (segments.length === 2 && segments[1].DepFrom) ||
          (segments.length > 2 && segments[2].DepFrom);
        return selectedLayover.includes(DepFrom);
      });
    }

    // Baggage Filter
    if (selectedBaggage.length > 0) {
      const normalizedSelectedBaggage = selectedBaggage.map(
        normalizeAndMapBaggageName
      );

      updateData = updateData.filter((item) => {
        const allowances =
          item?.PriceBreakDown?.flatMap((price) =>
            price?.Bag?.map((bag) =>
              normalizeAndMapBaggageName(bag?.Allowance || "")
            )
          ) || [];
        return allowances.some((allowance) =>
          normalizedSelectedBaggage.includes(allowance)
        );
      });
    }

    setFilteredFlights(updateData);
  };
  // Filter
  const debouncedFilter = debounce(filter, 300);
  useEffect(() => {
    debouncedFilter();
    return () => {
      debouncedFilter.cancel();
    };
  }, [
    selectedProvider,
    selectedRefundable,
    selectedAirlins,
    selectedLayover,
    selectedStops,
    selectedDepartTime,
    selectedArrivalTime,
    selectedBackDepartTime,
    selectedBackArrivalTime,
    //Baggage Filter
    selectedBaggage,
    flights,
    currentPage
  ]);
  const uniqueCarriers = Array.from(
    new Set(flights.filter((item) => item.Carrier).map((item) => item.Carrier))
  );
  const flightresults = uniqueCarriers.map((carrier) => {
    const filteredData = flights.filter((item) => item.Carrier === carrier);
    const minPrice = Math.min(
      ...filteredData.map((item) => parseInt(item.GrossFare))
    );
    const carrierName = filteredData[0].CarrierName;
    // change this property after add
    return {
      code: carrier,
      name: carrierName,
      price: minPrice.toString(),
    };
  });

  //Baggage Filter

  const baggageMapping = {
    "1p": "23kg",
    "2p": "46kg",
    "1n": "23kg",
    "2n": "46kg",
    "20k": "20kg",
    "30k": "30kg",
    "40k": "40kg",
    "45k": "45kg",
    "35k": "35kg",
  };

  const normalizeAndMapBaggageName = (name) => {
    const normalized = name?.toLowerCase().replace(/\s+/g, "") || "";
    return baggageMapping[normalized] || normalized; // Map using baggageMapping, fallback to normalized
  };
  const uniqueBaggage = Array.from(
    new Set(
      flights.flatMap(
        (flight) =>
          flight?.PriceBreakDown?.flatMap((price) =>
            price?.Bag?.map((bag) =>
              normalizeAndMapBaggageName(bag?.Allowance || "")
            )
          ) || []
      )
    )
  ).filter((name) => name); // Remove empty names

  // Log for debugging
  console.log("Mapped and Unique Baggage Allowances:", uniqueBaggage);

  const baggageResults = uniqueBaggage.map((baggage) => ({
    name: baggage, // Already normalized and mapped
  }));

  // const uniqueBaggage = Array.from(
  //   new Set(
  //     flights
  //       .filter((item) => item.PriceBreakDown)
  //       .map((item) => item.Bag)
  //       .map((item) => item.Allowance)
  //   )
  // );
  // const baggageresults = uniqueBaggage.map((baggage) => {
  //   const filteredData = flights.PriceBreakDown.filter((item) => item.Bag).map(
  //     (item) => item.Allowance === baggage
  //   );

  //   const allowance = filteredData[0].Allowance;
  //   // change this property after add
  //   return {
  //     code: baggage,
  //     name: allowance,
  //   };
  // });
  console.log("baggg", baggageResults);
  console.log("Flights", flightresults);

  return (
    <Box
      sx={{
        bgcolor: "#FFFFFF",
        height: "100%",
        boxSizing: "border-box !important",
      }}
    >
      <Box
        sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}
      >
        <NavBar />
      </Box>
      <Box
        sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
      >
        <AfterSearchBanner
          fromSearch={fromSearchText}
          toSearch={toSearchText}
          faddressText={faddress}
          toAddressText={toAddress}
          depDate={departureDate}
          arrDate={arrivalDate}
          fromSend={fromSendData}
          toSend={toSendData}
          adult={adultCount}
          child={childCount}
          infantCount={infant}
          classNa={className}
          paxResult={result}
          value={value}
          valueBoxs={valueBox}
          segments={segments}
          searchDatas={searchData}
        />
      </Box>

      <Container>
        <Box>
          <Grid
            container
            sx={{
              mt: {
                xs: "0px",
                sm: "0px",
                md: 2,
              },
            }}
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  borderRadius: "5px",
                  px: "10px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "18px",
                      color: "var(--primary-button)",
                    }}
                  >
                    {fromSendData}-{toSendData}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box>
                    <Link to="/">
                      <Button
                        sx={{
                          bgcolor: "var(--primary-button)",
                          color: "white",
                          fontSize: "12px",
                          // width: "10px",
                          px: "10px",
                          ":hover": {
                            bgcolor: "var(--primary-button)",
                            color: "white",
                          },
                        }}
                      >
                        Modify Search
                      </Button>
                    </Link>
                  </Box>
                  <Box>
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={toggleDrawer(true)}
                    >
                      <FilterAltIcon
                        sx={{
                          color: "var(--primary-button)",
                          fontSize: "35px",
                        }}
                      />
                    </Box>
                    {/* <Drawer
                    anchor="right"
                    open={isModifiyDrawerOpen}
                    onClose={toggleModifiyDrawer(false)}
                  >
                    <Box
                      sx={{ width: 250, padding: 2 }}
                      role="presentation"
                      onClick={toggleModifiyDrawer(false)}
                      onKeyDown={toggleModifiyDrawer(false)}
                    >
                      <FlightSearchBox />
                    </Box>
                  </Drawer> */}
                    <Drawer
                      anchor="right"
                      open={isDrawerOpen}
                      onClose={toggleDrawer(false)}
                    >
                      <Box
                        sx={{ width: 250, padding: 2 }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                      >
                        {/* <OneWayFilter
                        initialFetchData={initialFetchData}
                        setInitialFetchData={setInitialFetchData}
                        sortedBy={sortedBy}
                        setSortedBy={setSortedBy}
                        setfilteredData={setFilteredFlights}
                        filteredData={filteredFlights}
                        setCurrentPage={setCurrentPage}
                      /> */}

                        <Filter
                          flightData={flights}
                          tripType={tripType}
                          selectedProvider={selectedProvider}
                          handleProvider={handleProvider}
                          handleRefundable={handleRefundable}
                          handleAirLine={handleAirLine}
                          selectedAirlins={selectedAirlins}
                          handleStops={handleStops}
                          selectedStops={selectedStops}
                          selectedRefundable={selectedRefundable}
                          selectedLayover={selectedLayover}
                          handleDepartTime={handleDepartTime}
                          selectedDepartTime={selectedDepartTime}
                          selectedArrivalTime={selectedArrivalTime}
                          selectedBackDepartTime={selectedBackDepartTime}
                          selectedBackArrivalTime={selectedBackArrivalTime}
                          handleLayover={handleLayover}
                          uniqueLayover={uniqueLayover}
                          handleResetData={handleResetData}
                          uniqueCarriers={flightresults}
                          initialFetchData={initialFetchData}
                          setInitialFetchData={setInitialFetchData}
                          setfilteredData={setFilteredFlights}
                          filteredData={filteredFlights}
                          //Baggage Filter
                          uniqueBaggages={baggageResults}
                          handleBaggage={handleBaggage}
                          selectedBaggage={selectedBaggage}
                        />
                      </Box>
                    </Drawer>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              minHeight: "80vh",
            }}
          >
            {loading ? (
              <FlightLoading />
            ) : (
              <Box>
                {flights?.length > 0 ? (
                  <Grid container spacing={1.5}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2.5}
                      lg={2.5}
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: "white",
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                          maxHeight: "100%",
                          borderRadius: "10px",
                        }}
                      >
                        {loading ? (
                          <RoundwaySelecton />
                        ) : (
                          <Filter
                            flightData={flights}
                            tripType={tripType}
                            selectedProvider={selectedProvider}
                            handleProvider={handleProvider}
                            handleRefundable={handleRefundable}
                            handleAirLine={handleAirLine}
                            selectedAirlins={selectedAirlins}
                            handleStops={handleStops}
                            selectedStops={selectedStops}
                            selectedRefundable={selectedRefundable}
                            selectedLayover={selectedLayover}
                            handleDepartTime={handleDepartTime}
                            selectedDepartTime={selectedDepartTime}
                            selectedArrivalTime={selectedArrivalTime}
                            selectedBackDepartTime={selectedBackDepartTime}
                            selectedBackArrivalTime={selectedBackArrivalTime}
                            handleLayover={handleLayover}
                            uniqueLayover={uniqueLayover}
                            handleResetData={handleResetData}
                            uniqueCarriers={flightresults}
                            initialFetchData={initialFetchData}
                            setInitialFetchData={setInitialFetchData}
                            setfilteredData={setFilteredFlights}
                            filteredData={filteredFlights}
                            //Baggage Filter
                            uniqueBaggages={baggageResults}
                            handleBaggage={handleBaggage}
                            selectedBaggage={selectedBaggage}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
                      <Box>
                        {  paginatedFlights?.length > 0  ? (
                          <Box>
                          {paginatedFlights?.map((data, index) => (
                            <Box mb={1}>
                              <SingleFlight
                                className={className}
                                data={data}
                              />
                              
                            </Box>
                          ))}
                          <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            my: 2,
                          }}
                        >
                          <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                            color="secondary"
                          />
                        </Box>
                        </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height:"40vh"
                            }}
                          >
                            <Typography style={{ color: "red" }}>
                              No flights available
                            </Typography>
                          </Box>
                        )}
                        
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "red", bgcolor:"var(--primary)" }}>
                              No flights available
                            </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OneWaySearchResult;
