import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const stops = [
  { label: "Direct", value: 1 },
  { label: "1 Stop", value: 2 },
  { label: "1+ Stop", value: 3 },
];

const refundable = [
  { name: "Non Refundable", value: false },
  { name: "Refundable", value: true },
];

const Filter = memo(
  ({
    flightData,
    tripType,
    uniqueLayover,
    handleResetData,
    handleRefundable,
    handleProvider,
    selectedProvider,
    handleAirLine,
    handleStops,
    selectedStops,
    handleLayover,
    handleDepartTime,
    selectedDepartTime,
    selectedArrivalTime,
    selectedBackDepartTime,
    selectedBackArrivalTime,
    selectedAirlins,
    selectedRefundable,
    selectedLayover,
    uniqueCarriers,
    uniqueBaggages,
    handleBaggage,
    selectedBaggage,
  }) => {
    const [dateState, setDateState] = useState(1);
    const handleChange = (value) => {
      setDateState(value);
    };
    const [showAll, setShowAll] = useState(false);
    const visibleAirlines = showAll
      ? uniqueCarriers
      : uniqueCarriers.slice(0, 5);

    const visibleLayovers = showAll ? uniqueLayover : uniqueLayover.slice(0, 5); // Show all
    return (
      <Box
        className="label-css"
        sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          p: 0.5,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            p: 1,
            // mx: 1,
            borderBottom: "1px solid var(--footer-text-color)",
          }}
        >
          <Typography
            sx={{
              // color: "var(--primary-text-color)",
              fontSize: { xs: 14 },
              fontWeight: 500,
            }}
          >
            Filter
          </Typography>
          <button
            style={{
              border: "1px solid var(--primary-color)",
              borderRadius: "5px",
              color: "var(--white)",
              fontSize: "12px",
              backgroundColor: "var(--primary-color)",
              padding: "4px 6px",
            }}
            onClick={() => handleResetData()}
          >
            Reset
          </button>
        </Stack>
        <Box
          sx={{
            borderBottom: "1px solid var(--footer-text-color)",
          }}
        >
          <Accordion
            sx={{
              boxShadow: "none", // Remove shadow
              "&:before": {
                display: "none", // Remove default MUI divider line
              },
              px: "none",
            }}
            onClick={(e) => e.stopPropagation()} // Prevent event propagation
            onKeyDown={(e) => e.stopPropagation()} // Prevent key events from propagating
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                // bgcolor: "var(--primary-color)",
                // color: "#fff",
                // borderRadius: "5px",
                p: 0, // Remove all padding
                minHeight: "unset", // Prevent padding from reappearing
                "& .MuiAccordionSummary-content": {
                  margin: 0, // Remove margin inside content
                },
                px: 1,
              }}
            >
              <Typography
                sx={{
                  color: "var(--primary-text-color)",
                  fontSize: { xs: 13 },
                  fontWeight: 500,
                  py: 1,
                }}
              >
                Stops
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ButtonGroup fullWidth>
                <Grid container spacing={1}>
                  {stops.map((provider, i) => (
                    <Grid key={i} item xs={6}>
                      <Button
                        variant={
                          selectedStops.includes(provider.value)
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleStops(provider.value)}
                        sx={{
                          border: "1px solid gray",
                          textTransform: "none",
                          fontWeight: 600,
                          fontSize: { xs: 11 },
                          backgroundColor: selectedStops.includes(
                            provider.value
                          )
                            ? "var(--secondary-color)"
                            : "transparent",
                          "&:hover": {
                            backgroundColor: "var(--secondary-color)",
                            color: "#fff",
                          },
                          color: selectedStops.includes(provider.value)
                            ? "#fff"
                            : "var(--secondary-color)",
                        }}
                      >
                        {provider.label}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </ButtonGroup>
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* Airlines */}
        <Box
          sx={{
            // borderRadius: "5px",
            borderBottom: "1px solid var(--footer-text-color)",
          }}
        >
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none",
              "&:before": {
                display: "none",
              },
            }}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                p: 0,
                minHeight: "unset",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
                px: 1,
              }}
            >
              <Typography
                sx={{
                  color: "var(--primary-text-color)",
                  fontSize: { xs: 13 },
                  fontWeight: 500,
                  py: 1,
                }}
              >
                Airlines
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {visibleAirlines?.map((provider, i) => (
                  <Box
                    key={i}
                    sx={{
                      mb: 1,
                    }}
                  >
                    <label
                      htmlFor={provider.code}
                      className="form-control"
                      style={{
                        color: "var(--secondary-color)",
                        // fontSize: "10px",
                        fontWeight: 500,
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      <input
                        className="custom-checkbox"
                        style={{
                          width: "17px",
                          height: "17px",
                          paddingTop: "2px",
                        }}
                        type="checkbox"
                        id={provider.code}
                        checked={selectedAirlins.includes(provider.code)}
                        onChange={() => handleAirLine(provider.code)}
                      />
                      <Box
                        sx={{
                          width: "fit-content",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "13px",
                        }}
                      >
                        <img
                          src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${provider.code}.png`}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                        {provider.name}
                      </Box>
                    </label>
                  </Box>
                ))}
                {uniqueCarriers.length > 5 && (
                  <Button
                    onClick={() => setShowAll(!showAll)}
                    sx={{
                      mt: 2,
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "var(--primary-text-color)",
                      textTransform: "none",
                    }}
                  >
                    {showAll ? "Show Less" : "Show More"}
                  </Button>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* FareType */}
        <Box
          sx={{
            borderBottom: "1px solid var(--footer-text-color)",
          }}
        >
          <Accordion
            sx={{
              boxShadow: "none", // Remove shadow
              "&:before": {
                display: "none", // Remove default MUI divider line
              },
              px: 1,
              m: 0,
            }}
            onClick={(e) => e.stopPropagation()} // Prevent event propagation
            onKeyDown={(e) => e.stopPropagation()} // Prevent key events from propagating
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                p: 0, // Remove all padding
                minHeight: "unset", // Prevent padding from reappearing
                "& .MuiAccordionSummary-content": {
                  margin: 0, // Remove margin inside content
                },
                // px: 1,
                my: 0,
              }}
            >
              <Typography
                sx={{
                  color: "var(--primary-text-color)",
                  fontSize: { xs: 13 },
                  fontWeight: 500,
                  py: 1,
                }}
              >
                Fare Type
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 0, // Remove padding
                mt: 0, // Remove any margin on top
                mb: 0, // Remove any margin on bottom
              }}
            >
              <Box
                sx={{
                  px: 1,
                }}
              >
                {refundable.map((provider, i) => (
                  <div key={i}>
                    <label
                      htmlFor={provider.name}
                      className="form-control"
                      style={{
                        color: "var(--secondary-color)",
                        fontSize: 13,
                        fontWeight: 500,
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <input
                        className="custom-checkbox"
                        type="checkbox"
                        id={provider.name}
                        checked={selectedRefundable.includes(provider.value)}
                        onChange={() => handleRefundable(provider.value)}
                        style={{
                          width: "17px",
                          height: "17px",
                        }}
                      />
                      <Box
                        sx={{
                          fontSize: "13px",
                          pt: 0.5,
                        }}
                      >
                        {provider.name}
                      </Box>
                    </label>
                  </div>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* Baggage */}
        <Box
          sx={{
            borderBottom: "1px solid var(--footer-text-color)",
          }}
        >
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none", // Remove shadow
              "&:before": {
                display: "none", // Remove default MUI divider line
              },
              px: "none",
            }}
            onClick={(e) => e.stopPropagation()} // Prevent event propagation
            onKeyDown={(e) => e.stopPropagation()} // Prevent key events from propagating
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                p: 0, // Remove all padding
                minHeight: "unset", // Prevent padding from reappearing
                "& .MuiAccordionSummary-content": {
                  margin: 0, // Remove margin inside content
                },
                px: 1,
                my: 0,
                py: 0,
              }}
            >
              <Typography
                sx={{
                  color: "var(--primary-text-color)",
                  fontSize: { xs: 13 },
                  fontWeight: 500,
                  py: 1,
                }}
              >
                Baggage
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {uniqueBaggages.map((provider, i) => (
                  <div key={i}>
                    <label
                      htmlFor={provider.code}
                      className="form-control"
                      style={{
                        color: "var(--secondary-color)",
                        fontWeight: 500,
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <input
                        className="custom-checkbox"
                        style={{
                          width: "17px",
                          height: "17px",
                        }}
                        type="checkbox"
                        id={provider.name}
                        checked={selectedBaggage.includes(provider.name)}
                        onChange={() => handleBaggage(provider.name)}
                      />
                      <Box
                        sx={{
                          fontSize: "13px",
                          pt: 0.5,
                        }}
                      >
                        {provider.name}
                      </Box>
                    </label>
                  </div>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* Layover */}

        <Box
          sx={{
            borderBottom: "1px solid var(--footer-text-color)",
          }}
        >
          {uniqueLayover?.length > 0 ? (
            <Accordion
              sx={{
                boxShadow: "none",
                "&:before": {
                  display: "none",
                },
              }}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  p: 0,
                  minHeight: "unset",
                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                  },
                  px: 1,
                }}
              >
                <Typography
                  sx={{
                    color: "var(--primary-text-color)",
                    fontSize: { xs: 13 },
                    fontWeight: 500,
                    py: 1,
                  }}
                >
                  Layover Airport
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {visibleLayovers.map((provider, i) => (
                    <div key={i}>
                      <label
                        htmlFor={provider.code}
                        className="form-control"
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <input
                          className="custom-checkbox"
                          style={{
                            width: "17px",
                            height: "17px",
                          }}
                          type="checkbox"
                          id={provider.code}
                          checked={selectedLayover.includes(provider.code)}
                          onChange={() => handleLayover(provider.code)}
                        />
                        <Box
                          sx={{
                            fontSize: "13px",
                            // maxWidth: { md: "250px" },
                          }}
                          noWarp
                        >
                          {provider.name}
                        </Box>
                      </label>
                    </div>
                  ))}
                  {uniqueLayover.length > 5 && (
                    <Button
                      onClick={() => setShowAll(!showAll)}
                      sx={{
                        mt: 2,
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "var(--primary-text-color)",
                        textTransform: "none",
                      }}
                    >
                      {showAll ? "Show Less" : "Show More"}
                    </Button>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ) : (
            ""
          )}
        </Box>
      </Box>
    );
  }
);
export default Filter;
