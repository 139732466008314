import { Box, Container } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import slider1 from "../../asstes/img/discountsSlider/biksh.png";
import slider2 from "../../asstes/img/discountsSlider/slider1.png";
import slider3 from "../../asstes/img/discountsSlider/slider2.png";
import slider4 from "../../asstes/img/discountsSlider/biksh.png";
import "./DiscountSlider.css";
const isMobile = window.innerWidth <= 768;

const DiscountSlider = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box
      sx={{
        pt: 10,
        overflow: "hidden",
      }}
    >
      {/* uzzal */}
      <Container>
        <div className="slider-container">
          <Slider {...settings} style={{ position: "relative" }}>
            <div>
              <Box
                sx={{
                  height: {
                    xs: "100%",
                    sm: "100%",
                    md: "245px",
                    lg: "245px",
                  },
                }}
              >
                <img
                  src={slider1}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px !important",
                  }}
                />
              </Box>
            </div>
            <div>
              <Box
                sx={{
                  height: {
                    xs: "100%",
                    sm: "100%",
                    md: "245px",
                    lg: "245px",
                  },
                }}
              >
                <img
                  src={slider2}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px !important",
                  }}
                />
              </Box>
            </div>
            <div>
              <Box
                sx={{
                  height: {
                    xs: "100%",
                    sm: "100%",
                    md: "245px",
                    lg: "245px",
                  },
                }}
              >
                <img
                  src={slider3}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px !important",
                  }}
                />
              </Box>
            </div>
            <div>
              <Box
                sx={{
                  height: {
                    xs: "100%",
                    sm: "100%",
                    md: "245px",
                    lg: "245px",
                  },
                }}
              >
                <img
                  src={slider4}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px !important",
                  }}
                />
              </Box>
            </div>
            <div>
              <Box
                sx={{
                  height: {
                    xs: "100%",
                    sm: "100%",
                    md: "245px",
                    lg: "245px",
                  },
                }}
              >
                <img
                  src={slider1}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px !important",
                  }}
                />
              </Box>
            </div>
          </Slider>
        </div>
      </Container>
    </Box>
  );
};

export default DiscountSlider;
