import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AllDeals from "./AllDeals";
import FlightDeals from "./FlightDeals";
import GroupFareDeals from "./GroupFareDeals";
import HotealDeals from "./HotealDeals";
import TourDeals from "./TourDeals";
const isMobile = window.innerWidth <= 768;

const HotDeals = () => {
  const [value, setValue] = useState("all");
  const [hotDeals, setHotDeals] = useState([]);
  const [error, setError] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // useEffect(() => {
  //   // Define the API URL using the `value` prop
  //   const url = `http://192.168.10.91:3000/hotdeals/${value}`;

  //   // Function to fetch data
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(url);
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       const result = await response.json();
  //       setHotDeals(result);
  //     } catch (error) {
  //       setError(error);
  //     } finally {
  //       // setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [value]);

  return (
    <Box sx={{ my: "20px" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "30px" }}>Hot Deals</Typography>
          <Box>
            <TabContext value={value}>
              <Box
                sx={{
                  pt: {
                    xs: "5px",
                    sm: "5px",
                    md: "40px",
                    lg: "40px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  <select
                    style={{
                      background: "white",
                      color: "var(--primary-button)",
                      fontSize: "20px",
                      border: "none",
                      outline: "none",
                      width: "290px",
                      height: "40px",
                    }}
                    onChange={(e) => handleChange(e, e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="flight">Flight</option>
                    <option value="groupFare">Group Fare</option>
                    <option value="hotel">Hotel</option>
                    <option value="tour">Tour</option>
                  </select>
                </Box>

                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  indicatorColor="none"
                  textColor="white"
                  sx={{
                    bgcolor: "white",
                    p: {
                      xs: "0px",
                    },
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  <Tab
                    label="All"
                    value="all"
                    sx={{
                      textTransform: "Capitalize",
                      color: "var(--primary-button)",
                      opacity: "1",
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--button-color)",
                        color: "var(--primary-text-color)",
                        opacity: "1",
                      },
                      width: { md: "100px", xs: "fit-content" },
                      fontWeight: 600,
                      margin: { xs: "0px 0px" },
                      fontSize: { xs: "8px", sm: "10px", md: "12px" },
                    }}
                  />
                  <Tab
                    label="Flight"
                    value="flight"
                    sx={{
                      textTransform: "Capitalize",
                      color: "var(--primary-button)",
                      opacity: "1",
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--button-color)",
                        color: "var(--primary-text-color)",
                        opacity: "1",
                      },
                      width: { md: "100px", xs: "fit-content" },
                      fontWeight: 600,
                      margin: { xs: "0px 0px" },
                      fontSize: { xs: "8px", sm: "10px", md: "12px" },
                    }}
                  />
                  <Tab
                    label="Group Fare"
                    value="groupFare"
                    sx={{
                      textTransform: "Capitalize",
                      color: "var(--primary-button)",
                      opacity: "1",
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--button-color)",
                        color: "var(--primary-text-color)",
                        opacity: "1",
                      },
                      width: { md: "130px", xs: "fit-content" },
                      fontWeight: 600,
                      margin: { xs: "0px 0px" },
                      fontSize: { xs: "8px", sm: "10px", md: "12px" },
                    }}
                  />
                  <Tab
                    label="Hotel"
                    value="hotel"
                    sx={{
                      textTransform: "Capitalize",
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--button-color)",
                        color: "var(--primary-text-color)",
                        opacity: "1",
                      },
                      color: "var(--primary-button)",
                      opacity: "1",
                      width: { md: "100px", xs: "fit-content" },
                      fontWeight: 600,
                      margin: { xs: "0px 0px" },
                      fontSize: { xs: "8px", sm: "10px", md: "12px" },
                    }}
                  />
                  <Tab
                    label="Tour"
                    value="tour"
                    sx={{
                      textTransform: "Capitalize",
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--button-color)",
                        color: "var(--primary-text-color)",
                        opacity: "1",
                      },
                      fontWeight: 600,
                      color: "var(--primary-button)",
                      opacity: "1",
                      width: { md: "100px", xs: "fit-content" },
                      margin: { xs: "0px 0px" },
                      fontSize: { xs: "8px", sm: "10px", md: "12px" },
                    }}
                  />
                </TabList>

                {/* {isMobile ? (
                  <select
                    style={{
                      background: "white",
                      color: "var(--primary-button)",
                      fontSize: "20px",
                      border: "none",
                      outline: "none",
                      width: "340px",
                      height: "40px",
                    }}
                    onChange={(e) => handleChange(e, e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="flight">Flight</option>
                    <option value="groupFare">Group Fare</option>
                    <option value="hotel">Hotel</option>
                    <option value="tour">Tour</option>
                  </select>
                ) : (
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    indicatorColor="none"
                    textColor="white"
                    sx={{ bgcolor: "white" }}
                  >
                    <Tab
                      label="All"
                      value="all"
                      sx={{
                        textTransform: "Capitalize",
                        color: "var(--primary-button)",
                        opacity: "1",
                        "&.MuiTab-root.Mui-selected": {
                          backgroundColor: "var(--button-color)",
                          color: "var(--primary-text-color)",
                          opacity: "1",
                        },
                        width: { lg: "100px", xs: "fit-content" },
                        fontWeight: 600,
                        margin: { xs: "0px 0px" },
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                      }}
                    />
                    <Tab
                      label="Flight"
                      value="flight"
                      sx={{
                        textTransform: "Capitalize",
                        color: "var(--primary-button)",
                        opacity: "1",
                        "&.MuiTab-root.Mui-selected": {
                          backgroundColor: "var(--button-color)",
                          color: "var(--primary-text-color)",
                          opacity: "1",
                        },
                        width: { lg: "110px", xs: "fit-content" },
                        fontWeight: 600,
                        margin: { xs: "0px 0px" },
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                      }}
                    />
                    <Tab
                      label="Group Fare"
                      value="groupFare"
                      sx={{
                        textTransform: "Capitalize",
                        color: "var(--primary-button)",
                        opacity: "1",
                        "&.MuiTab-root.Mui-selected": {
                          backgroundColor: "var(--button-color)",
                          color: "var(--primary-text-color)",
                          opacity: "1",
                        },
                        width: { lg: "120px", xs: "fit-content" },
                        fontWeight: 600,
                        margin: { xs: "0px 0px" },
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                      }}
                    />
                    <Tab
                      label="Hotel"
                      value="hotel"
                      sx={{
                        textTransform: "Capitalize",
                        "&.MuiTab-root.Mui-selected": {
                          backgroundColor: "var(--button-color)",
                          color: "var(--primary-text-color)",
                          opacity: "1",
                        },
                        color: "var(--primary-button)",
                        opacity: "1",
                        width: { lg: "110px", xs: "fit-content" },
                        fontWeight: 600,
                        margin: { xs: "0px 0px" },
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                      }}
                    />
                    <Tab
                      label="Tour"
                      value="tour"
                      sx={{
                        textTransform: "Capitalize",
                        "&.MuiTab-root.Mui-selected": {
                          backgroundColor: "var(--button-color)",
                          color: "var(--primary-text-color)",
                          opacity: "1",
                        },
                        fontWeight: 600,
                        color: "var(--primary-button)",
                        opacity: "1",
                        width: { lg: "100px", xs: "fit-content" },
                        margin: { xs: "0px 0px" },
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                      }}
                    />
                  </TabList>
                )} */}
              </Box>
            </TabContext>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "30px 0px",
          }}
        >
          {value === "all" ? (
            <Box sx={{ padding: "0px" }}>
              <AllDeals />
            </Box>
          ) : value === "flight" ? (
            <Box sx={{ padding: "0px" }}>
              <FlightDeals />
            </Box>
          ) : value === "groupFare" ? (
            <Box sx={{ padding: "0px" }}>
              <GroupFareDeals />
            </Box>
          ) : value === "hotel" ? (
            <Box sx={{ padding: "0px" }}>
              <HotealDeals />
            </Box>
          ) : value === "tour" ? (
            <Box sx={{ padding: "0px" }}>
              <TourDeals />
            </Box>
          ) : null}
        </Box>
      </Container>
    </Box>
  );
};

export default HotDeals;
