import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import Swal from "sweetalert2";

const GoogleLogIn = ({ loading }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(""); // Added state for error handling

  const handleSignWithGoogle = async (data) => {
    console.log("data", data);
    try {
      const response = await fetch(
        "https://flyitsearch-backend-api-539319089408.asia-east1.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: data.access_token,
            email: data.email,
            firstName: data.given_name,
            lastName: data.family_name,
            picture: data.picture,
          }),
        }
      );
      const userData = await response.json();
      if (!response.ok) {
        if (userData.code === "EMAIL_EXISTS") {
          setError(
            "This email is already registered with a different login method"
          );
          return;
        }
        throw new Error(userData.error || "Login failed");
      }
      console.log(userData);
      // setUser(userData);
      navigate("/profile");
    } catch (error) {
      console.error("Login failed:", error);
      setError("Login failed. Please try again.");
    }
  };

  // {
  //     "client_id":"845432119752-vd2kuhktdhkoonrdqia2c2o0nfgnkbvm.apps.googleusercontent.com",
  //     "client_secret":"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",
  //     "redirect_url":"https://flyitsearch-backend-api-539319089408.asia-east1.run.app/social-site/google/redirect",
  //     "grant_type":"authorization_code"
  // }

  return (
    <div>
      {loading ? (
        <p>Loading...</p> // Display a loading indicator if `loading` is true
      ) : (
        <LoginSocialGoogle
          client_id="1089805692153-lafif3jic63i66gu84l0c3f8eeu69vus.apps.googleusercontent.com"
          scope="openid profile email"
          access_type="offline"
          redirect_uri="http://localhost:3000/profile"
          ux_mode="popup"
          cookie_policy="single_host_origin"
          fetch_basic_profile={true}
          prompt="select_account"
          isOnlyGetToken={true}
          onResolve={({ provider, data }) => {
            handleSignWithGoogle(data);
          }}
          onReject={(err) => {
            console.error("Google login failed:", err);
            Swal.fire({
              icon: "error",
              title: "Login Failed",
              text: "Google login was rejected. Please try again.",
            });
          }}
        >
          {/* <button>Continue with Google</button> */}
        </LoginSocialGoogle>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}{" "}
      {/* Display error message */}
    </div>
  );
};

export default GoogleLogIn;
