import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

const PassengerListSlikton = ({ booking }) => {
  return (
    <>
      <TableRow>
        {[...Array(5)].map((_, cellIndex) => (
          <TableCell key={cellIndex}>
            <Skeleton animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

export default PassengerListSlikton;
