import { Box } from "@mui/material";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from "moment";
import { format } from "date-fns";
import PassengerListSlikton from "../PassengerListSlikton";

const PassengerDetails = ({ loading, booking, quesData }) => {
  console.log("ami booking passenger theke booking data", booking);
  return (
    <Box sx={{ overflowX: "auto" }}>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ borderRadius: "2px" }}
      >
        <Table aria-label="booking details">
          <TableHead
            sx={{ backgroundColor: "var(--primary-button)", color: "white" }}
          >
            <TableRow sx={{ color: "white" }}>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "white",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "white",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                Gender
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "white",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                DOB
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "white",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                PAX
              </TableCell>
              {quesData[0]?.PassportRequired === true && (
                <>
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      color: "white",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                    }}
                  >
                    Passport No
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      color: "white",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                    }}
                  >
                    Expire Date
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      color: "white",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                    }}
                  >
                    Passport Copy
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      color: "white",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                    }}
                  >
                    Visa Copy
                  </TableCell>
                </>
              )}

              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "white",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                Booking Date
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <PassengerListSlikton />
          ) : (
            <TableBody>
              {quesData[0] && typeof quesData[0] === "object" ? (
                quesData[0]?.PassengerList?.map((paxInfo, i) => (
                  <TableRow
                    key={i}
                    hover
                    sx={{
                      "&:hover": { backgroundColor: "#f5f5f5" },
                      border: "3px solid #f5f5f5 ",
                    }}
                  >
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      {paxInfo.Title} {paxInfo?.FirstName} {paxInfo?.LastName}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      {paxInfo.Gender}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      {moment(paxInfo?.DateOfBirth).format("DD MMM YYYY")}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      {paxInfo?.PaxType}
                    </TableCell>

                    {quesData[0]?.PassportRequired === true && (
                      <>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                          }}
                        >
                          {paxInfo?.PassportNumber}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                          }}
                        >
                          {moment(paxInfo?.PassportExpiryDate).format(
                            "DD MMM YYYY"
                          )}
                        </TableCell>

                        {booking?.personId?.map((copy, index) => (
                          <React.Fragment key={index}>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                fontSize: "12px",
                              }}
                            >
                              <a
                                href={copy?.passport}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Passport
                              </a>
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                fontSize: "12px",
                              }}
                            >
                              <a
                                href={copy?.visa}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Visa
                              </a>
                            </TableCell>
                          </React.Fragment>
                        ))}

                        <></>
                      </>
                    )}
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      {quesData[0]?.BookingDate
                        ? format(
                            new Date(quesData[0]?.BookingDate),
                            "dd MMMM yyyy"
                          )
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <p>No data available</p>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PassengerDetails;
