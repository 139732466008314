import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { format } from "date-fns";
import secureLocalStorage from "react-secure-storage";
import BookingQueDetailsSlikton from "./BookingQueDetailsSlikton";

const BookingQueDetails = ({ loading, quesData }) => {
  const userData = secureLocalStorage.getItem("userData");

  const [remainingTime, setRemainingTime] = useState("Calculating...");

  const getRemainingTime = () => {
    if (!quesData || !quesData[0] || !quesData[0].TimeLimit) {
      return "No Time Limit Set";
    }

    const timeLimit = new Date(quesData[0].TimeLimit);
    const now = new Date();
    const diffInSeconds = Math.floor((timeLimit - now) / 1000);

    if (diffInSeconds <= 0) return "Expired";

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return `${hours}H : ${minutes} Min : ${seconds} Sec`;
  };

  useEffect(() => {
    // Update remaining time every second
    const timer = setInterval(() => {
      const time = getRemainingTime();
      setRemainingTime(time);

      // Clear the interval if the time has expired
      if (time === "Expired") {
        clearInterval(timer);
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [quesData]);

  return (
    <Box>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ borderRadius: "2px" }}
      >
        <Table aria-label="booking details">
          <TableHead
            sx={{ backgroundColor: "var(--primary-button)", color: "white" }}
          >
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                Booking ID
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                Route
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                Type
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                Fare Type
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                Booked At
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                Booked By
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                Expired In
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <BookingQueDetailsSlikton />
            ) : (
              <TableRow
                hover
                sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
              >
                <TableCell>{quesData[0]?.BookingId}</TableCell>
                <TableCell>
                  {quesData[0]?.AllLegsInfo[0]?.DepFrom}-
                  {quesData[0]?.AllLegsInfo[0]?.ArrTo}
                </TableCell>
                <TableCell>{quesData[0]?.TripType}</TableCell>
                <TableCell sx={{ color: "#388e3c", fontWeight: 500 }}>
                  {quesData[0]?.Refundable === true
                    ? " Refundable"
                    : "Non-Refundable"}
                </TableCell>
                <TableCell>
                  {quesData[0]?.BookingDate
                    ? format(new Date(quesData[0]?.BookingDate), "dd MMMM yyyy")
                    : "N/A"}
                </TableCell>
                <TableCell>{userData?.name}</TableCell>
                <TableCell>{remainingTime}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BookingQueDetails;
