const Banks = [
  {
    id: 1,
    name: "Bangladesh Bank",
    type: "Central Bank",
  },
  {
    id: 2,
    name: "Sonali Bank Limited",
    type: "State-Owned Commercial Bank",
  },
  {
    id: 3,
    name: "Janata Bank Limited",
    type: "State-Owned Commercial Bank",
  },
  {
    id: 4,
    name: "Agrani Bank Limited",
    type: "State-Owned Commercial Bank",
  },
  {
    id: 5,
    name: "Rupali Bank Limited",
    type: "State-Owned Commercial Bank",
  },
  {
    id: 6,
    name: "Bangladesh Development Bank Limited",
    type: "State-Owned Specialized Bank",
  },
  {
    id: 7,
    name: "BASIC Bank Limited",
    type: "State-Owned Specialized Bank",
  },
  {
    id: 8,
    name: "EXIM Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 9,
    name: "Brac Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 10,
    name: "Eastern Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 11,
    name: "Islami Bank Bangladesh Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 12,
    name: "Dutch-Bangla Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 13,
    name: "Mutual Trust Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 14,
    name: "NCC Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 15,
    name: "Prime Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 16,
    name: "Pubali Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 17,
    name: "Social Islami Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 18,
    name: "South Bangla Agriculture and Commerce Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 19,
    name: "Standard Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 20,
    name: "The City Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 21,
    name: "Trust Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 22,
    name: "United Commercial Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 23,
    name: "First Security Islami Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 24,
    name: "Jamuna Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 25,
    name: "Meghna Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 26,
    name: "Mercantile Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 27,
    name: "Modhumoti Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 28,
    name: "NRB Commercial Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 29,
    name: "One Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 30,
    name: "Shahjalal Islami Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 31,
    name: "Southeast Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 32,
    name: "Uttara Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 33,
    name: "AB Bank Limited",
    type: "Private Commercial Bank",
  },
  {
    id: 34,
    name: "Bangladesh Krishi Bank",
    type: "State-Owned Specialized Bank",
  },
  {
    id: 35,
    name: "Rajshahi Krishi Unnayan Bank",
    type: "State-Owned Specialized Bank",
  },
];
export default Banks;