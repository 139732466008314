import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import OneWay from "../OneWay/OneWay";
import RoundWay from "../RoundWay/RoundWay";
import MultiCity from "../MultiCity/MultiCity";
const isMobile = window.innerWidth <= 768;

const CustomRadio = styled(Radio)(({ theme }) => ({
  "&.MuiRadio-root": {
    color: "var(--button-color)",
  },
  "&.Mui-checked": {
    color: "var(--button-color) !important",
  },
  "& .MuiSvgIcon-root": {
    fontSize: isMobile ? "15px" : "25px",
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  ".MuiFormControlLabel-label": {
    display: "flex",
    alignItems: "center",
    gap: "3px",
    color: "var(--primary-text-color)",
    fontSize: "13px",
    fontWeight: 600,
    whiteSpace: "nowrap", // Prevent text from wrapping
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "var(--button-color)",
  },
  ".mt-checkbox-wrapper": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    width: "100%",
    height: "fit-content",
    "@media (max-width: 600px)": {
      justifyContent: "space-between", // Adjust for small screens
    },
  },
  ".mt-checkbox": {
    appearance: "none",
    margin: "0px",
    width: "18px",
    height: "18px",
    border: "1px solid var(--button-color)",
    borderRadius: "4px",
    background: "var(--white)",
    outline: "none",
    transition: "background 0.3s",
  },
}));
const FlightSearchBox = ({
  segments,
  type,
  setType,
  fromSearchText,
  setFromSearchText,
  toSearchText,
  setToSearchText,
  departureDate,
  setDepartureDate,
  returningDate,
  setReturningDate,
  travelDate,
  setTravelDate,
  value,
  setValue,
  from,
  setFrom,
  to,
  setTo,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  setClassName,
  handleClassName,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  changeTo,
  setChangeTo,
  valueBox,
  setValueBox,
  searchData,
  setSearchData,
}) => {
  const handleChangess = (event, newValue) => {
    setValueBox(newValue);
  };
  return (
    <Box
    // sx={{
    //   position: "relative",
    //   zIndex: 1000,
    //   bgcolor: "red",
    // }}
    >
      <Box sx={{ py: "10px" }}>
        <RadioGroup row value={valueBox} onChange={handleChangess}>
          <CustomFormControlLabel
            sx={{ color: "red !important" }}
            value="oneway"
            control={<CustomRadio />}
            label="One Way"
          />
          <CustomFormControlLabel
            value="roundway"
            control={<CustomRadio />}
            label="Round Way"
          />
          <CustomFormControlLabel
            value="multicity"
            control={<CustomRadio />}
            label="Multi City"
          />
        </RadioGroup>
        <Box my={2}>
          {valueBox === "oneway" && (
            <OneWay
              type={type}
              valueBox={valueBox}
              setValueBox={setValueBox}
              setType={setType}
              value={value}
              setValue={setValue}
              fromSearchText={fromSearchText}
              setFromSearchText={setFromSearchText}
              toSearchText={toSearchText}
              setToSearchText={setToSearchText}
              departureDate={departureDate}
              setDepartureDate={setDepartureDate}
              returningDate={returningDate}
              setReturningDate={setReturningDate}
              travelDate={travelDate}
              setTravelDate={setTravelDate}
              from={from}
              setFrom={setFrom}
              to={to}
              setTo={setTo}
              faddress={faddress}
              setfaddress={setfaddress}
              toAddress={toAddress}
              setToAddress={setToAddress}
              fromSendData={fromSendData}
              setFromSendData={setFromSendData}
              toSendData={toSendData}
              setToSendData={setToSendData}
              adultCount={adultCount}
              setAdultCount={setAdultCount}
              childCount={childCount}
              setChildCount={setChildCount}
              infant={infant}
              setInfant={setInfant}
              result={result}
              setResult={setResult}
              className={className}
              handleClassName={handleClassName}
              setClassName={setClassName}
              setChangeState={setChangeState}
              changeState={changeState}
              changeFrom={changeFrom}
              setChangeFrom={setChangeFrom}
              searchData={searchData}
              setSearchData={setSearchData}
              segments={segments}
            />
          )}
          {valueBox === "roundway" && (
            <RoundWay
              type={type}
              setType={setType}
              valueBox={valueBox}
              setValueBox={setValueBox}
              value={value}
              setValue={setValue}
              fromSearchText={fromSearchText}
              setFromSearchText={setFromSearchText}
              toSearchText={toSearchText}
              setToSearchText={setToSearchText}
              departureDate={departureDate}
              setDepartureDate={setDepartureDate}
              returningDate={returningDate}
              setReturningDate={setReturningDate}
              travelDate={travelDate}
              setTravelDate={setTravelDate}
              from={from}
              setFrom={setFrom}
              to={to}
              setTo={setTo}
              faddress={faddress}
              setfaddress={setfaddress}
              toAddress={toAddress}
              setToAddress={setToAddress}
              fromSendData={fromSendData}
              setFromSendData={setFromSendData}
              toSendData={toSendData}
              setToSendData={setToSendData}
              adultCount={adultCount}
              setAdultCount={setAdultCount}
              childCount={childCount}
              setChildCount={setChildCount}
              infant={infant}
              setInfant={setInfant}
              result={result}
              setResult={setResult}
              className={className}
              handleClassName={handleClassName}
              setClassName={setClassName}
              setChangeState={setChangeState}
              changeState={changeState}
              changeFrom={changeFrom}
              setChangeFrom={setChangeFrom}
              changeTo={changeTo}
              setChangeTo={setChangeTo}
              searchData={searchData}
              setSearchData={setSearchData}
              segments={segments}
            />
          )}
          {valueBox === "multicity" && (
            <MultiCity
              type={type}
              setType={setType}
              valueBox={valueBox}
              setValueBox={setValueBox}
              value={value}
              setValue={setValue}
              fromSearchText={fromSearchText}
              setFromSearchText={setFromSearchText}
              toSearchText={toSearchText}
              setToSearchText={setToSearchText}
              departureDate={departureDate}
              setDepartureDate={setDepartureDate}
              returningDate={returningDate}
              setReturningDate={setReturningDate}
              travelDate={travelDate}
              setTravelDate={setTravelDate}
              from={from}
              setFrom={setFrom}
              to={to}
              setTo={setTo}
              faddress={faddress}
              setfaddress={setfaddress}
              toAddress={toAddress}
              setToAddress={setToAddress}
              fromSendData={fromSendData}
              setFromSendData={setFromSendData}
              toSendData={toSendData}
              setToSendData={setToSendData}
              adultCount={adultCount}
              setAdultCount={setAdultCount}
              childCount={childCount}
              setChildCount={setChildCount}
              infant={infant}
              setInfant={setInfant}
              result={result}
              setResult={setResult}
              className={className}
              handleClassName={handleClassName}
              setClassName={setClassName}
              setChangeState={setChangeState}
              changeState={changeState}
              changeFrom={changeFrom}
              setChangeFrom={setChangeFrom}
              changeTo={changeTo}
              setChangeTo={setChangeTo}
              searchData={searchData}
              setSearchData={setSearchData}
              segments={segments}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FlightSearchBox;
