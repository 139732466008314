import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

const BookingQueDetailsSlikton = () => {
  return (
    <>
      <TableRow>
        {[...Array(7)].map((_, cellIndex) => (
          <TableCell key={cellIndex}>
            <Skeleton animation="wave" sx={{ width: "100%", height: "30px" }} />
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

export default BookingQueDetailsSlikton;
