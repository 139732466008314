import React, { useEffect, useState } from "react";
import { Typography, Grid, Paper, Divider, Box } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

const Cancellation = ({ searchId, resultId }) => {
  const [fareData, setFareData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const api =
          "https://flyitsearch-backend-api-539319089408.asia-east1.run.app/flights/flh/farePolicyMiniRules";

        const body = {
          SearchId: searchId,
          ResultId: resultId,
        };

        const response = await fetch(api, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        if (response.ok) {
          const data = await response.json();
          setFareData(data || { miniRules: [] });
        } else {
          console.error("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchId, resultId]);
  return (
    <div>
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "0.5px dashed var(--secondary-color)",
            pb: "15px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "13px",
                  lg: "13px",
                },
                fontWeight: 600,
              }}
            >
              Time Frame
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
              }}
            >
              (From Scheduled flight departure)
            </Typography>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Typography
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "13px",
                  lg: "13px",
                },
                fontWeight: 600,
              }}
            >
              Airline Fee + FlyItSearch Fee
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
              }}
            >
              (Per passenger)
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "0.5px dashed var(--secondary-color)",
            py: "10px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
              }}
            >
              {" "}
              0 hours to 72 hours
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
              }}
            >
              <span style={{ fontWeight: 600 }}>Adult :</span> Airline Ploicy +
              FlyItSearch Fee
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            py: "15px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
                fontWeight: 500,
              }}
            >
              72 hours to 365 days
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
                fontWeight: 500,
              }}
            >
              <span style={{ fontWeight: 600 }}>Adult :</span> Airline Ploicy +
              FlyItSearch Fee
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "0.5px dashed var(--secondary-color)",
            pb: "15px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "13px",
                  lg: "13px",
                },
                fontWeight: 600,
              }}
            >
              Time Frame
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
              }}
            >
              (After flight departure)
            </Typography>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Typography
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "13px",
                  lg: "13px",
                },
                fontWeight: 600,
              }}
            >
              Airline Fee + FlyItSearch Fee
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
              }}
            >
              (Per passenger)
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: "15px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
              }}
            >
              (After flight departure)
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "12px",
                  lg: "12px",
                },
              }}
            >
              <span style={{ fontWeight: 600 }}>Adult :</span> Non Refundable
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            border: "1px solid red",
            borderRadius: "5px",
            background: "#FDF5F5",
            p: "5px",
          }}
        >
          <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <WarningIcon sx={{ color: "red" }} />
            <Typography
              sx={{
                fontSize: {
                  xs: "10.5px",
                  sm: "10.5px",
                  md: "11px",
                  lg: "11px",
                },
                color: "red",
              }}
            >
              {" "}
              *Important: The airline fee is indicative. FlyItSearch does not
              guarantee the accuracy of this information. All fees mentioned are
              per passenger.
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Cancellation;
