import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo1 from "../../asstes/img/footer img/transpraentlogo.svg";
import logo from "../../asstes/img/flyitsearchlogo.svg";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import bg from "../../asstes/img/bg.svg";

import left from "../../asstes/img/footer img/leftlogo.svg";
import right from "../../asstes/img/footer img/rightlogo.svg";

const Footer = () => {
  // const backgroundStyle = {
  //   backgroundImage: `url(${bg})`,
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   // backgroundPosition: "center",
  //   minHeight: "100%", // Set minimum height
  // };

  // const backgroundStyle = {
  //   backgroundImage: `url(${bg})`,
  //   backgroundSize: "cover", // Ensures the entire image fits in the container
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "center",
  //   minHeight: "100%", // Provide a default height for smaller screens
  // };
  return (
    <Box>
      <Box
        // style={backgroundStyle}
        sx={{
          width: "100%",
          // minHeight: {
          //   xs: "300px", // Height for extra-small screens
          //   sm: "400px", // Height for small screens
          //   md: "500px", // Height for medium screens
          //   lg: "600px", // Height for large screens
          // },
          // bgcolor: "#26303a",
          // height: {
          //   xs: "100%",
          //   sm: "100%",
          //   md: "400px",
          //   lg: "400px",
          // },
          // pt: {
          //   xs: "50px",
          //   sm: "50px",
          //   md: "200px",
          //   lg: "200px",
          // },
          py: 4,
          background: "linear-gradient(180deg, #151F29 0%, #2A3248 100%)",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 80,
            zIndex: 1,
          }}
        >
          <img
            src={left}
            style={{
              width: "60%",
              height: "15%",
            }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            right: 0,
            // left:0,
            zIndex: 1,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <img
            src={right}
            style={{
              width: "60%",
              height: "15%",
            }}
          />
        </Box>
        <Container>
          <Grid container spacing={3} sx={{ zIndex: 2, position: "relative" }}>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Box>
                <Typography
                  sx={{
                    color: "var(--footer-text-color)",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Need assistance?
                </Typography>
                <Box sx={{ pt: "20px" }}>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <LocalPhoneIcon
                          sx={{
                            color: "var(--primary-color)",
                            fontWeight: 600,
                            fontSize: "13px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontSize: "13px",
                          }}
                        >
                          +880 1736-987906
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: "5px",
                          gap: "10px",
                        }}
                      >
                        <MailIcon
                          sx={{
                            color: "var(--primary-color)",
                            fontWeight: 600,
                            fontSize: "13px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontSize: "13px",
                          }}
                        >
                          SUPPORT @Flyitsearch.com
                        </Typography>
                      </Box>
                    </Typography>
                  </Link>
                  <Box sx={{ mt: "15px" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ color: "white", fontSize: "13px" }}>
                          Our Support team is available <br /> around the clock.{" "}
                          <br />
                          Contact us via Messenger or phone.
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Box>
                <Typography
                  sx={{
                    color: "var(--footer-text-color)",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  About
                </Typography>
                <Box sx={{ pt: "20px" }}>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      Blog
                    </Typography>
                  </Link>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                        paddingTop: "15px",
                      }}
                    >
                      Our Story
                    </Typography>
                  </Link>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                        paddingTop: "15px",
                      }}
                    >
                      Terms and conditions
                    </Typography>
                  </Link>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                        paddingTop: "15px",
                      }}
                    >
                      Privacy Policy
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Box>
                <Typography
                  sx={{
                    color: "var(--footer-text-color)",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Help & Support
                </Typography>
                <Box sx={{ pt: "20px" }}>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      FAQs
                    </Typography>
                  </Link>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                        paddingTop: "15px",
                      }}
                    >
                      Contact Us
                    </Typography>
                  </Link>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                        paddingTop: "15px",
                      }}
                    >
                      Terms and conditions
                    </Typography>
                  </Link>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                        paddingTop: "15px",
                      }}
                    >
                      Payment Method
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Box>
                <Box>
                  <img style={{ height: "50px", width: "90%" }} src={logo} />
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "white",
                      width: "100%",
                      mt: "5px",
                    }}
                  >
                    Seamless Travel Grandet
                  </Typography>
                </Box>

                <Box sx={{ pt: "20px" }}>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      Flyit Search Head Office-
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--footer-text-color)",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      DCC7, North Ibrahimpur, <br />
                      Mirpur 14,Dhaka -1206,
                    </Typography>
                  </Link>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: { xs: "2px", md: "20px" },
                      mt: "20px",
                    }}
                  >
                    <Box>
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://www.facebook.com/FlyitSearch"
                        target="_blank"
                      >
                        <FaFacebookF
                          style={{ color: "white", fontSize: "20px" }}
                        />
                      </a>
                    </Box>
                    <Box>
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://www.instagram.com/fly_it_search?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                        target="_blank"
                      >
                        <InstagramIcon
                          sx={{ color: "white", fontSize: "20px" }}
                        />
                      </a>
                    </Box>
                    <Box>
                      <XIcon sx={{ color: "white", fontSize: "20px" }} />
                    </Box>

                    <Box>
                      <FaLinkedinIn
                        style={{ color: "white", fontSize: "20px" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ pt: 0 }}>
            <Grid
              container
              //   spacing={6}
              pt={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "13px",
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "center",
                    },
                  }}
                >
                  © copyright 2025 All Rights Reserved by{" "}
                  <span
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    FlyitSearch
                  </span>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
