import { Box, Container, Grid } from "@mui/material";
import React from "react";
import AfterSearchBanner from "../../AfterSearch/AfterSearchBanner";
import TourAfterSearchBanner from "../TourAfterSearchBanner/TourAfterSearchBanner";
import TourFilter from "../TourFliter/TourFilter";
import SingleTourPack from "../SingleTourPack/SingleTourPack";

const TourSearchResult = () => {
  return (
    <Box>
      {/* <TourAfterSearchBanner /> */}
      <Container>
        <Grid container sx={{ mt: "200px" }} spacing={2}>
          <Grid item xs={12} sm={12} md={2.4} lg={2.5}>
            <Box
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                height: "100%",
                borderRadius: "10px",
              }}
            >
              <TourFilter />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2.4} lg={9.5}>
            <Grid container>
              <Grid item lg={12}></Grid>
              <Grid item lg={12}>
                <SingleTourPack />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TourSearchResult;
