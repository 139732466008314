import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import banner from "../../../asstes/img/tour/TourBanner (2).png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
const SingleTourPack = () => {
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        height: "100%",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        borderRadius: "10px",
        p: "10px 20px",
      }}
    >
      <Grid container spacing={4}>
        <Grid item md={10}>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <img src={banner} style={{ height: "150px" }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "var( --meta-black)",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                Land Of Himalayan Majesty And Cultural Riches
              </Typography>
              <Typography
                sx={{
                  color: "var(--primary-button)",
                  fontSize: "15px",
                  fontWeight: 600,
                }}
              >
                3 Nights 4 Days
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  py: "10px",
                }}
              >
                <LocationOnIcon sx={{ color: "#9C9797" }} />
                <Typography sx={{ color: "#9C9797", fontSize: "14px" }}>
                  Tanguar Haor, Sunamganj
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                  py: "15px",
                }}
              >
                <Box
                  sx={{
                    width: "85px",
                    height: "25px",
                    bgcolor: "var(--primary-color)",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ color: "#716200", fontSize: "12px" }}>
                    Family Tour
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "85px",
                    height: "25px",
                    bgcolor: "var(--primary-color)",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ color: "#716200", fontSize: "12px" }}>
                    Group Tour
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "85px",
                    height: "25px",
                    bgcolor: "var(--primary-color)",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ color: "#716200", fontSize: "12px" }}>
                    Relax Tour
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "90px",
            }}
          >
            <Typography
              sx={{
                fontSize: "25px",
                color: "var(--depature-color)",
                textAlign: "right",
              }}
            >
              42,000৳
            </Typography>
            <Box>
              <Link to="/tourdetails">
                <Button
                  sx={{
                    color: "var(--white)",
                    bgcolor: "var(--primary-button)",
                    ":hover": {
                      color: "var(--white)",
                      bgcolor: "var(--primary-button)",
                    },
                    textTransform: "capitalize",
                    width: "120px",
                  }}
                >
                  View Details
                </Button>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleTourPack;
