import { Box, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavBar from "../../Shared/NavBar/NavBar";
import PassengerInfo from "./PassengerInfo/PassengerInfo";
import FareBreakdown from "./FareBreakdown/FareBreakdown";
import Support from "./Support/Support";
import TimeSesation from "./TimeSesation/TimeSesation";
import OneWayFlightDetils from "../OneWayFlightDetils/OneWayFlightDetils";
import { useLocation } from "react-router-dom";
import RoundwayFlightDetails from "../RoundwayFlightDetails/RoundwayFlightDetails";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import commaNumber from "comma-number";
import { TabContext, TabPanel } from "@mui/lab";
import Baggage from "../OneWayFlightDetils/Baggage";
import MulticityFlightDetails from "../MultiCity/MulticityFlightDetails/MulticityFlightDetails";

const BookingDetaills = () => {
  const [airPriceData, setAirPriceData] = useState([]);
  const token = secureLocalStorage.getItem("access_token");
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const data = location?.state || {};
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ResultId = data?.ResultId;
  const SearchId = data?.SearchId;

  useEffect(() => {
    const apiURL =
      "https://flyitsearch-backend-api-539319089408.asia-east1.run.app/flights/flh/priceCheck";
    const body = { ResultId: ResultId, SearchId: SearchId };

    const fetchAirPriceData = async () => {
      try {
        setLoading(true); // Start loading
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Fetched data:", data);
        setAirPriceData(data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchAirPriceData();
  }, [ResultId, SearchId]);

  useEffect(() => {
    if (!loading && airPriceData[0]?.RePriceStatus === "PriceChanged") {
      Swal.fire({
        html: `Selected option is available with the updated fare. <br>
               New Fare: BDT <span style="color: red;">${
                 commaNumber(airPriceData[0]?.NetFare) || "N/A"
               }</span>`,
      });
    }
  }, [loading, airPriceData]);

  return (
    <Box sx={{ backgroundColor: "#EBF0F4", height: "100%" }}>
      <NavBar />
      <Container>
        <Grid container sx={{ pt: "50px" }} spacing={4}>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ bgcolor: "#C1DAFF" }}>
                  <Typography
                    sx={{
                      color: "#13406B",
                      fontSize: "13px",
                      fontWeight: 600,
                      px: "15px",
                      py: "5px",
                    }}
                  >
                    Flight Information
                  </Typography>
                </Box>
                <Box sx={{ pt: "20px", bgcolor: "white", px: "10px" }}>
                  {data?.TripType === "Oneway" ? (
                    <OneWayFlightDetils data={data} />
                  ) : data?.TripType === "Return" ? (
                    <RoundwayFlightDetails data={data} />
                  ) : data?.TripType === "Multicity" ? (
                    <MulticityFlightDetails data={data} />
                  ) : (
                    <OneWayFlightDetils data={data} />
                  )}
                  
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                <Box sx={{ bgcolor: "#C1DAFF" }}>
                  <Typography
                    sx={{
                      color: "#13406B",
                      fontSize: "13px",
                      fontWeight: 600,
                      px: "15px",
                      py: "5px",
                    }}
                  >
                    Baggage Information
                  </Typography>
                </Box>
                <Box sx={{ py: "20px", bgcolor: "white", px: "10px" }}>
                  {/* <TabContext value={value}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        width: { xs: "300px", sm: "fit-content" },
                        background: "var(--primary-color)",
                        height: {
                          lg: "35px",
                          md: "40px",
                          sm: "40px",
                          xs: "40px",
                        },
                        minHeight: "100%",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: "1",
                        borderRadius: "0px 3px 3px 0px",

                        ".MuiTabs-flexContainer": {
                          flexWrap: { xs: "nowrap", sm: "nowrap", md: "wrap" },
                          padding: {
                            lg: "0px",
                            md: "0px",
                            sm: "0px 10px",
                            xs: "0px 10px",
                          },
                        },
                        "& button": {
                          color: "var(--meta-black)",
                          opacity: "1",
                          borderBottom: "0",
                          textTransform: "capitalize",
                          padding: {
                            lg: "0 20px",
                            md: "0 20px",
                          },
                          fontSize: { xs: "10px", sm: "12px", md: "14px" },
                        },

                        "& button.Mui-selected": {
                          background: "var(--primary-button)",
                          color: "var(--white)",
                          opacity: "1",
                        },
                      }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                      >
                        <Tab label="Baggage" value="1" />
                        <Tab label="Extra Baggage" value="2" />

                        
                      </Tabs>
                    </Box>
                  
                    <TabPanel
                      value="1"
                      style={{
                        padding: "10px 20px",
                        background: "#EBF0F4",
                        borderRadius: "5px",
                      }}
                    >
                      <Baggage data={data} />
                    </TabPanel>
              
                    <TabPanel
                      value="2"
                      style={{
                        padding: "10px 20px",
                        background: "#EBF0F4",
                        borderRadius: "5px",
                      }}
                    >
                      comming....
                    </TabPanel>
                   
                  </TabContext> */}
                  <Box
                    style={{
                      padding: "10px 20px",
                      background: "#EBF0F4",
                      borderRadius: "5px",
                    }}
                  >
                    <Baggage data={data} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ bgcolor: "#C1DAFF", mt: "20px" }}>
                  <Typography
                    sx={{
                      color: "#13406B",
                      fontSize: "13px",
                      fontWeight: 600,
                      px: "15px",
                      py: "5px",
                    }}
                  >
                    Passenger Information
                  </Typography>
                </Box>
                <Box sx={{ pt: "20px" }}>
                  <PassengerInfo data={data} airPriceData={airPriceData} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid contaner>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FareBreakdown
                  loading={loading}
                  data={data}
                  airPriceData={airPriceData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                <Support />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} my={3}>
                <TimeSesation />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BookingDetaills;
